import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { FC, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import defaultAvatar from "../../../../../assets/images/default-avatar.png";
import { IProject } from "../../../../../shared/models/project";
import { ISprint } from "../../../../../shared/models/sprint";
import { IStatus } from "../../../../../shared/models/status";
import { ITaskHistory } from "../../../../../shared/models/task";
import { IUserInfo } from "../../../../../shared/models/userInfo";
// import { getTimeFixBug } from "../../../../../shared/utils";
import TaskTag from "../../../../../shared/models/taskTag";
import { TASK_FIELDS } from "../new_edit_task";
import "./index.scss";

const HistoryWidget: FC<{ histories: ITaskHistory[] }> = ({ histories = [] }) => {
    const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const mapUser: Map<string, IUserInfo> = new Map();
    for (let user of users) {
        mapUser.set(user._id, user);
    }
    const [showAllHistories, setShowAllHistories] = useState(false);
    const previewHistories = histories.slice(0, showAllHistories ? histories.length : 3);
    return (
        <div className="histories-widget">
            <div>
                {previewHistories.map((action, index) => {
                    if(action.field == TASK_FIELDS.expectPoints) {
                        return null;
                    }
                    const user = mapUser.get(action.userID);
                    return (
                        <div key={index} className="history">
                            <img src={!!user?.avatar ? user?.avatar : defaultAvatar} />
                            <div>
                                <div className="history-title">
                                    <strong>{`${user?.firstName} ${user?.lastName} `}</strong>
                                    updated the{" "}
                                    <strong>
                                        {getFieldLabel(action.field)}
                                    </strong>
                                    <span className="history-time">
                                        on {action.time ? moment(new Date(action.time)).format("lll") : ""}
                                    </span>
                                </div>
                                <div className="history-changes">
                                    <HistoryLabelOld action={action} mapUser={mapUser} />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {showAllHistories ? (
                <Button
                    className="show-all-histories-btn"
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => setShowAllHistories(false)}
                >
                    Show less
                </Button>
            ) : (
                <Button
                    className="show-all-histories-btn"
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => setShowAllHistories(true)}
                >
                    Show all histories
                </Button>
            )}
        </div>
    );
};

const getFieldLabel = (field: string) => {
    if(field === "point") {
        return "Estimated Points";
    }
    if(field === "testLink") {
        return "Link Test";
    }
    if(field === "tagValue") {
        return "Số lượng";
    }
    return field;
}

const HistoryLabelOld: FC<{
    action: ITaskHistory;
    mapUser: Map<string, IUserInfo>;
}> = ({ action, mapUser }) => {
    const projects: IProject[] = useSelector((state: any) => state.projectState.projects ?? []);
    const sprints: ISprint[] = useSelector((state: any) => state.sprintState.sprints ?? []);
    const statuses: IStatus[] = useSelector((state: any) => state.statusState.status ?? []);
    // const campaigns: ICampaign[] = useSelector((state: any) => state.campaignState.campaigns ?? []);
    const isActionTimeValid = action.time instanceof Date && !isNaN(action.time.getTime());
    const actionTimeInMillis = isActionTimeValid ? action.time.getTime() : null;
    const newHistory = actionTimeInMillis && actionTimeInMillis > 1675123724000;
    const taskTags: TaskTag[] = useSelector((state: any) => state.taskTagState.taskTags ?? []);

    const getUserNameById = (id: string) => {
        const user = mapUser.get(id);
        if (user) {
            return user.lastName + " " + user.firstName;
        }
        return "";
    };
    const getListUserNameByIds = (ids: string[]) => {
        const users: string[] = [];
        for (let id of ids) {
            const user = mapUser.get(id);
            if (user) {
                users.push(user.lastName + " " + user.firstName);
            }
        }
        return users.join(", ");
    };
    const getHistoryActionLabel = (field: string, val: string) => {
        switch (field) {
            case TASK_FIELDS.status:
                return statuses.find((s) => s._id === val)?.title;
            case TASK_FIELDS.sprint:
                return sprints.find((spint) => spint._id === val)?.name;
            case TASK_FIELDS.project:
                return projects.find((project) => project._id === val)?.title;
            case "description":
                return "";
            case TASK_FIELDS.assignee:
                return getUserNameById(val);
            case TASK_FIELDS.reporter:
                return getUserNameById(val);
            case TASK_FIELDS.tester:
                if (newHistory) {
                    return getListUserNameByIds(val.split(","));
                }
                return val;
            case "testers":
                let message = "";
                let valCopy = [val];
                if (typeof val === "string") {
                    valCopy = val.split(",");
                }
                valCopy.forEach((t, index) => {
                    if (index === valCopy.length - 1) {
                        message += getUserNameById(t);
                        return;
                    }
                    message += getUserNameById(t) + ", ";
                });
                return message;
            case TASK_FIELDS.leadDev:
                return getUserNameById(val);
            case TASK_FIELDS.inform:
                if (newHistory) {
                    return getListUserNameByIds(val.split(","));
                }
                let msg = "";
                let valueCopy = [val];
                if (typeof val === "string") {
                    valueCopy = val.split(",");
                }
                valueCopy.forEach((t, index) => {
                    if (index === valueCopy.length - 1) {
                        msg += getUserNameById(t);
                        return;
                    }
                    msg += getUserNameById(t) + ", ";
                });
                return msg;
            case "campaign":
                if (val) {
                    let obj = JSON.parse(val);
                    return obj.map((e: any) => e.label).toString();
                }
                return "";
            case TASK_FIELDS.followers:
                if (newHistory) {
                    return getListUserNameByIds(val.split(","));
                }
                if (val) {
                    let obj = JSON.parse(val);
                    return obj.map((e: any) => e.label).toString();
                }
                return "";
            case TASK_FIELDS.deadline:
                return val ? moment(val).format("HH:mm:ss - DD/MM/YYYY") : "";
            case TASK_FIELDS.tags:
                return taskTags.find((tag) => tag._id === val)?.name;
            default:
                return val;
        }
    };
    return (
        <>
            <div style={{ textDecoration: "line-through" }}>{getHistoryActionLabel(action.field, action.from)}</div>
            <FontAwesomeIcon icon={faLongArrowAltRight as IconProp} />
            <div>{getHistoryActionLabel(action.field, action.to)}</div>
        </>
    );
};

export default HistoryWidget;
