import { FC } from "react";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateTask } from "../../../redux/actions/task.action";
import Config from "../../../shared/models/config";
import { IStatus } from "../../../shared/models/status";
import { ITask, ITaskHistory, TaskHistory } from "../../../shared/models/task";
import { IUserGroup } from "../../../shared/models/userGroup";
import { IUserInfo } from "../../../shared/models/userInfo";
import { checkPermissionChangeTask } from "../../../shared/permissions";
import { checkRequirementsChangeStatusField } from "../../../util";
import { TASK_FIELDS } from "../task-preview/edit-task-modal/new_edit_task";
import "./style.scss";
import { RootState } from "../../../redux/reducers";

const Column: FC<{
    columnId: string;
    children: React.ReactNode;
}> = ({ columnId, children }) => {
    const status: IStatus[] = useSelector((state: RootState) => state.statusState.status ?? []);
    const userInfo: IUserInfo = useSelector((state: RootState) => state.authState.user!);
    const tasks: ITask[] = useSelector((state: RootState) => state.taskState.tasks ?? []);
    const userGroups: IUserGroup[] = useSelector((state: RootState) => state.userGroupState.userGroups);
    const dispatch = useDispatch();
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "TASK",
            drop: (item: any) => handleDrop(item),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [columnId]
    );
    const handleDrop = async (task: ITask) => {
        const currentColumn: IStatus | undefined = status.find((status) => status._id == task.statusId);
        const nextColumn: IStatus | undefined = status.find((status) => status._id == columnId);
        if (!nextColumn) {
            toast(() => <div>Không tìm thấy cột tiếp theo!</div>, {
                position: "top-center",
                type: "error",
            });
            return;
        }
        if (!currentColumn) {
            toast(() => <div>Không tìm thấy cột hiện tại!</div>, {
                position: "top-center",
                type: "error",
            });
            return;
        }
        if (nextColumn._id == currentColumn._id) {
            // task not changes
            return;
        }
        if (!checkRequirementsChangeStatusField(task, nextColumn._id)) return;
        const newTask: ITask = {
            ...task,
            ...{
                statusId: nextColumn._id,
                status: nextColumn,
            },
        };
        const time = new Date().getTime();
        const changes: ITaskHistory[] = [];
        changes.push(
            new TaskHistory({
                field: TASK_FIELDS.status,
                from: task.statusId,
                to: newTask.statusId,
                userID: userInfo._id,
                time,
            })
        );
        const histories = (changes ?? []).concat(task.histories ?? []);
        newTask.histories = histories;
        //check task con da done het chua thi moi duoc keo sang done
        //khi thay doi task cha thi can check de xem co thay doi task con hay khong
        if (
            checkPermissionChangeTask({
                task: task,
                newTask: newTask,
                user: userInfo,
                onFeedBack(msg) {
                    toast(() => <div>{msg}</div>, {
                        position: "top-center",
                        type: "error",
                    });
                },
                userGroups
            })
        ) {
            let allowEdit = true;
            if (nextColumn._id === Config.COLUMN_STATUS.DONE.mongoId) {
                allowEdit = checkAllChildTasksAreDone(task, tasks);
            }
            if (allowEdit) dispatch(updateTask({ ...newTask, updateStatus: true }));
        }
    };

    return (
        <div
            className={"sprint-column min-width " + (isOver ? "active" : "no")}
            ref={drop as any}
            style={{
                marginRight: columnId === Config.COLUMN_STATUS.DONE.mongoId ? "0" : "16px",
            }}
        >
            {children}
        </div>
    );
};

export const checkAllChildTasksAreDone = (task: ITask, alltasks: ITask[]) => {
    let done = true;
    let queue = [task];
    while (!!queue.length) {
        let item = queue.shift();
        if (item) {
            for (let t of alltasks) {
                if (t.parent?._id === item?._id || t.parentId === item?._id) {
                    //neu la task con
                    queue.push(t);
                    if (t?.statusId !== Config.COLUMN_STATUS.DONE.mongoId) done = false;
                }
            }
        }
    }
    if (!done)
        toast(() => <div>Chưa hoàn thành hết subtask!</div>, {
            position: "top-center",
            type: "error",
        });
    return done;
};
export default Column;
