import { Flag } from "@material-ui/icons";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Checkbox } from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import filter_icon from "../../../assets/icon/filter_icon.png";
import TaskTag, { TaskTagStatus } from "../../../shared/models/taskTag";

export class SelectFilterItem {
    id: string
    name: string
    color?: string

    constructor(id: string, name: string, color?: string) {
        this.id = id;
        this.name = name;
        this.color = color;
    }

    isReporter() {
        return this.id === 'Reporter'
    }

    isTester() {
        return this.id === 'Tester'
    }
    
    isBug() {
        return this.id === 'Bug'
    }

    isInform() {
        return this.id === 'Inform'
    }

    isLeader() {
        return this.id === 'Leader'
    }

    isFollower() {
        return this.id === 'Follower'
    }

    isAssignee() {
        return this.id === 'Assignee'
    }

    isTestToday() {
        return this.id === 'TestToday'
    }

    isTag() {
        return !!this.color && !this.isAssignee() && !this.isTestToday() && !this.isLeader() 
        && !this.isFollower() && !this.isReporter() && !this.isTester() && !this.isBug() && !this.isInform();
    }

    isNull() {
        return !(this.id && this.name);
    }
}


const SelectFilter: FC<{
    value: SelectFilterItem[];
    onChange: (value: SelectFilterItem[]) => void;
    setShowAllTasks: () => void;
    showAllTasks: boolean;
}> = ({ value, onChange, setShowAllTasks, showAllTasks }) => {
    const taskTags: TaskTag[] = useSelector((state: any) => state.taskTagState?.taskTags ?? []);
    const [openFilter, setOpenFilter] = useState(false);
    const handleToggleOff = () => {
        setOpenFilter(false);
    };
    const ref = useRef<HTMLDivElement | null>(null);
    const items: SelectFilterItem[] = [
        new SelectFilterItem("Reporter", "Reporter"),
        new SelectFilterItem("Tester", "Tester"),
        new SelectFilterItem("Bug", "Bug"),
        new SelectFilterItem("Inform", "Inform"),
        new SelectFilterItem("Leader", "Leader"),
        new SelectFilterItem("Follower", "Follower"),
        new SelectFilterItem("Assignee", "Assignee"),
        new SelectFilterItem("TestToday", "TestToday"),
    ];
    if(taskTags?.length) {
        items.push(new SelectFilterItem("", "Tag"));
        items.push(...taskTags.filter(taskTag => taskTag.status === TaskTagStatus.ACTIVE)
            .map<SelectFilterItem>((taskTag) => new SelectFilterItem(taskTag._id, taskTag.name, taskTag.color)));
    }
    return (
        <div className={"folter-btn-wrapper radius-12 " + (showAllTasks ? "active" : "")} title="Chọn bộ lọc">
            <div className="radius-12 background-btn-filter">
                <div
                    ref={ref}
                    className={
                        "select-filter radius-12 align-item-center justify-content-center " +
                        ((value.length > 0 || openFilter) ? "active" : "")
                    }
                    data-tip
                    data-for="clearAllFiltersTip_y"
                    onClick={() => setOpenFilter(!openFilter)}
                >
                    <img className="icon" src={filter_icon} style={{ width: "18px", height: "18px" }} />
                    {value.length > 0 && (
                        <div
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onChange([]);
                            }}
                            style={{
                                position: "absolute",
                                bottom: "-6px",
                                right: "-6px",
                            }}
                        >
                            <NotInterestedIcon htmlColor="#eee" />
                        </div>
                    )}
                </div>
            </div>

            <div
                style={{
                    fontSize: "16px",
                    textAlign: "center",
                }}
                onClick={(e) => {
                    setShowAllTasks();
                }}
                className={"show-all-tasks " + (showAllTasks ? "active color-5" : "white")}
            >
                All Tasks
            </div>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={ref?.current}
                open={openFilter}
                onClose={handleToggleOff}
                TransitionComponent={Fade}
            >
                {items.map((item: SelectFilterItem) => {
                    if(item.isNull()) {
                        return <div key={'filter-item-' + item.name} style={{ fontWeight: 'bold', padding: '8px 12px' }}>{item.name}</div>
                    }
                    return (
                        !!item && (
                            <MenuItem
                                key={'filter-item-' + item.id}
                                className="filter-item"
                            >
                                <Checkbox 
                                    name="ISelectFilter" 
                                    checked={value.some(u => u.id === item.id)} 
                                    id={item.id} 
                                    style={{ padding: 0 }} 
                                    onChange={(e) => {
                                        if(e.target.checked) {
                                            onChange([...value, item])
                                        } else {
                                            onChange(value.filter(u => u.id !== item.id))
                                        }
                                    }}
                                />
                                <label style={{ marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                                    { item.color && (
                                        <div style={{ color: item.color }}>
                                            <Flag color="inherit" fontSize="small" />
                                        </div>
                                    ) }
                                    {item.name}
                                </label>
                            </MenuItem>
                        )
                    );
                })}
            </Menu>
        </div>
    );
};

export default SelectFilter;