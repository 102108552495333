interface IRequest {
  data: any | any[];
}

const initState: IRequest = {
  data: null,
}
const Request = (state = initState, action: any) => {
  switch (action.type) {
    case 'SET_REQUEST':
      return { ...state, data: action.data };
    case 'UPDATE_REQUEST':
      if (state && Array.isArray(state) && state.length !== 0) {
        return { ...state, 
          data: {
            ...state.data,
            avatar: action.data,
            name: action.data,
            imgItem: action.data,
            price: action.data,
            date: action.data,
            seen: action.data
          }
        }
      } else {
        return {
          data: {
            ...state.data,
            avatar: action.data,
            name: action.data,
            imgItem: action.data,
            price: action.data,
            date: action.data,
            seen: action.data
          }
        }
      }
    case 'DELETE_REQUEST':
      const dataDelete = action.data;
      if(Array.isArray(state)) {
        let arr = state.map((item: any) => {
          return {
            id: item.id,
            userId: item.userId,
            username: item.username,
            item: item.item,
            status: item.status,
            description: item.description
          }
        })
        arr = arr.filter(function (item) {
          return (item.id !== dataDelete.id);
        })
        return arr;
      }
      return state;
    default:
      return state;
  }
};

export default Request;