
const initState: any[] = [];

const SprintsReducer = (state = initState, action: any) => {
    switch (action.type) {
        case 'SET_SPRINTS':
            return action.data;
        case 'ADD_SPRINT':
            return state.concat(action.data);
        default:
            return state;
    }
};

export default SprintsReducer;