import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ReactTooltip from "react-tooltip";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import Constants from "../../../constant";
import { RootState } from "../../../redux/reducers";
import Config from "../../../shared/models/config";
import { ISprint } from "../../../shared/models/sprint";
import { ITask } from "../../../shared/models/task";
import TaskTag from "../../../shared/models/taskTag";
import { IUserInfo } from "../../../shared/models/userInfo";
import { isAdmin } from "../../../util";
import { checkContainsArray } from "../sprint-columns/DashboardSprintColumnsV2";
import "./style.scss";

const statusDoneStatusId = [Config.COLUMN_STATUS.DONE.mongoId, Config.COLUMN_STATUS.PROD_VALIDATION.mongoId, Config.COLUMN_STATUS.TO_DEPLOY.mongoId];

const checkTaskDone = (task: ITask) => {
    return (statusDoneStatusId.includes(task.status?._id ?? "") || statusDoneStatusId.includes(task.statusId)) || task.checkPoint;
}

const checkTaskDoneInDeadline = (task: ITask) => {
    if(task.deadline && task.finishedDate > -1) {
        if(task.deadline.getTime() < task.finishedDate) {
            return true;
        }
    }
    return false;
}

const calcPerformance = (totalPointTagDone: number) => {
    const performance = (totalPointTagDone - 80) / 80 * 100;    
    return Math.round(performance / 10) * 10;
}

const calcPoint = (tasks: ITask[], taskTags: TaskTag[]) => {
    let totalPointTag = 0,
    totalPointTagDone = 0,
    totalPointNoTag = 0,
    totalPointNoTagDone = 0,
    totalTaskTag = 0,
    totalTaskTagDone = 0,
    totalTaskNoTag = 0,
    totalTaskNoTagDone = 0,
    totalPointDoneInDeadline = 0,
    totalPointInDeadline = 0;
    for(const task of tasks) {
        const currentTag = taskTags.find((tag) => task.tags.includes(tag._id));
        if (currentTag) {
            const point = task.tagValue * currentTag.point / currentTag.value;
            totalTaskTag++;
            totalPointTag += point;
            if(checkTaskDone(task)) {
                totalTaskTagDone += 1;
                totalPointTagDone += point;
            }
            if(task.deadline && task.deadline.getTime() > -1 && task.finishedDate > -1) {
                if(checkTaskDoneInDeadline(task)) {
                    totalPointDoneInDeadline += point;
                }
                totalPointInDeadline += point;
            }
        } else {
            const point = task.point ?? 0;
            totalTaskNoTag++;
            totalPointNoTag += point;
            if(checkTaskDone(task)) {
                totalTaskNoTagDone += 1;
                totalPointNoTagDone += point;
            }
            if(task.deadline && task.deadline.getTime() > -1 && task.finishedDate > -1) {
                if(checkTaskDoneInDeadline(task)) {
                    totalPointDoneInDeadline += point;
                }
                totalPointInDeadline += point;
            }
        }
    }
    return {
        totalPointTag: Math.round(totalPointTag),
        totalPointTagDone: Math.round(totalPointTagDone),
        totalPointNoTag: Math.round(totalPointNoTag),
        totalPointNoTagDone: Math.round(totalPointNoTagDone),
        totalTaskTag: Math.round(totalTaskTag),
        totalTaskTagDone: Math.round(totalTaskTagDone),
        totalTaskNoTag: Math.round(totalTaskNoTag),
        totalTaskNoTagDone: Math.round(totalTaskNoTagDone),
        totalPointDoneInDeadline: Math.round(totalPointDoneInDeadline),
        totalPointInDeadline: Math.round(totalPointInDeadline),
        totalTask: Math.round((totalTaskTag + totalTaskNoTag)),
        totalPoint: Math.round((totalPointTag + totalPointNoTag)),
        taskDone: Math.round((totalTaskTagDone + totalTaskNoTagDone)),
        pointDone: Math.round((totalPointTagDone + totalPointNoTagDone)),
        performance: calcPerformance(totalPointTagDone)
    }
}


const DashboardUsersFilter: FC<{
    currentUserIds: string[];
    onSelectedUser: (id: string) => void;
    showTooltip?: boolean;
    isFilterTask?: boolean;
    filterUsers?: (user: IUserInfo) => boolean;
    setMemberGroupIds?: Set<string>;
    currentSprintId?: string;
    userTaskScore?: any; //{userId: ITaskScores}
}> = ({
    currentUserIds = [],
    onSelectedUser,
    showTooltip = true,
    isFilterTask = true,
    filterUsers,
    setMemberGroupIds,
    currentSprintId,
    userTaskScore,
}) => {
    const tasks: ITask[] = useSelector((state: RootState) => state.taskState.tasks ?? []);
    const userInfo = useSelector((state: RootState) => state.authState.user!);
    const users: IUserInfo[] = useSelector((state: RootState) => state.userInfoState.userInfos ?? []);
    const sprints: ISprint[] = useSelector((state: RootState) => state.sprintState.sprints ?? []);
    const taskTags: TaskTag[] = useSelector((state: RootState) => state.taskTagState?.taskTags ?? []);
    const ref = useRef<HTMLDivElement | null>(null);
    const [overflow, setOverflow] = useState(false);
    const params: any = useParams();
    const { id } = params;
    let sprintID: string = currentSprintId ?? id;
    if (!sprintID) {
        sprintID = sprints.find((s: any) => s.activeSprint)?._id ?? "";
    }
    // const currentSprint = sprints.find((sprint: any) => sprint._id === sprintID) ?? sprints[0];
    let usersRelatedWithMemberGroup = new Set<string>();
    //admin: all users
    //lead: all users in group and users related to group's users (task)
    tasks.forEach((task) => {
        // find all users related to group's members
        if (task.statusView !== Config.STATUS_PUBLIC) return;
        const list = [
            task.assigneeId,
            task.reporterId,
            ...task.testerIds,
            task.leadDevId,
            ...task.inform,
        ]
        if (
            checkContainsArray(Array.from(setMemberGroupIds ?? []), list) ||
            task.createdUserId == userInfo._id
        ) {
            const setuser = new Set<string>(list);
            setuser.forEach((i) => {
                if (i && !setMemberGroupIds?.has(i)) usersRelatedWithMemberGroup.add(i);
            });
        }
    });

    useEffect(() => {
        setOverflow(isOverflown());
    }, [usersRelatedWithMemberGroup]);
    return (
        <div style={{ display: "flex", alignItems: "flex-start" }} id="filter-user-container">
            {overflow && (
                <IconButton onClick={() => ref.current?.scroll({ behavior: "smooth", left: 0 })} style={{ left: "8px" }}>
                    <ArrowBackIosIcon />
                </IconButton>
            )}
            <div className="users-filter" ref={ref} id="users-filter-element">
                {users
                    .sort((a, b) => (a._id == userInfo?._id ? -1 : 1))
                    .filter(
                        filterUsers
                            ? filterUsers
                            : (user) => {
                                  //show user have related with
                                  return (
                                      user.status == Constants.STATUS_PUBLIC &&
                                      (isAdmin(userInfo)
                                          ? true
                                          : setMemberGroupIds?.has(user._id) || usersRelatedWithMemberGroup?.has(user._id))
                                  );
                              }
                    )
                    .map((user) => {
                        let countOtherRole = 0;
                        let assignedTasks = tasks.filter((task) => {
                            // find all tasks which are assigned to a user
                            if (task.assignee?._id === user._id) {
                                //tat ca task con deu co task cha (da xu ly o getAllTaskAPI)
                                // totalPoint += task?.point ?? 0;
                                return true;
                            }
                            if (
                                task.reporter?._id === user._id || // else count report or tester role
                                task.testerIds?.indexOf(user._id) > -1 ||
                                task.leadDevId === user._id || //
                                task.inform.includes(user._id) //
                            ) {
                                countOtherRole++;
                            }
                            return false;
                        });
                        if (assignedTasks.length === 0 && countOtherRole === 0 && isFilterTask) {
                            return null; //khong co task thi khong hien
                        }
                        const data = calcPoint(assignedTasks, taskTags);
                        const avatar = !!user.avatar ? user.avatar : defaultAvatar;
                        return (
                            <div
                                key={user._id ?? user._id}
                                onClick={() => onSelectedUser(user._id)}
                                data-tip
                                data-for={user._id}
                                style={{ width: "44px" }}
                            >
                                <img src={avatar} className={currentUserIds?.indexOf(user._id) > -1 ? "active" : ""} />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <p>{user.firstName}</p>
                                </div>

                                {showTooltip && (
                                    <ReactTooltip id={user._id} type="info">
                                        <div><strong>{user.firstName + ' ' + user.lastName}</strong></div>
                                        <table className="table table-bordered" style={{ color: 'white', textAlign: 'left' }}>
                                            <thead>
                                                <tr>
                                                    <th>Số task hoàn thành / Tổng số task</th>
                                                    <td>{data.taskDone} / {data.totalTask}</td>
                                                </tr>
                                                <tr>
                                                    <th>Số Point hoàn thành / Tổng số Point</th>
                                                    <td>{data.pointDone} / {data.totalPoint}</td>
                                                </tr>
                                                <tr>
                                                    <th>Tổng số point gắn tag hoàn thành</th>
                                                    <td>{data.totalPointTagDone} / {data.totalPointTag}</td>
                                                </tr>
                                                <tr>
                                                    <th>Tổng số point không gắn tag hoàn thành</th>
                                                    <td>{data.totalPointNoTagDone} / {data.totalPointNoTag}</td>
                                                </tr>
                                                {data.totalPointInDeadline > 0 && (
                                                    <tr>
                                                        <th>Tỉ lệ hoàn thành đúng deadline / Tổng số task có deadline</th>
                                                        <td>{data.totalPointDoneInDeadline} / {data.totalPointInDeadline} = {
                                                            Math.round(data.totalPointDoneInDeadline / data.totalPointInDeadline * 100)
                                                        }%</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th>Hiệu suất</th>
                                                    <td>{data.performance}%</td>
                                                </tr>
                                            </thead>
                                            
                                        </table>
                                    </ReactTooltip>
                                )}
                            </div>
                        );
                    })}
            </div>
            {overflow && (
                <IconButton
                    onClick={() =>
                        ref.current?.scroll({
                            behavior: "smooth",
                            left: 9999,
                        })
                    }
                    style={{ right: "2px" }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            )}
        </div>
    );
};

function isOverflown() {
    let element = document.getElementById("users-filter-element");
    if (!!!element) return false;
    return element.scrollWidth > element.clientWidth;
}
export default DashboardUsersFilter;
