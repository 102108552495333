import { IBasic } from "./basic";

export enum TaskTagStatus {
    ACTIVE = 1,
    INACTIVE = 0
}

export default class TaskTag implements IBasic {
    _id: string;
    name: string;
    description: string;
    color: string;
    point: number; // sô point tương ứng với các task lặp đi lặp lại
    value: number; // giá trị của các task lặp đi lặp lại
    createdAt: number;
    updatedAt: number;
    userGroupId: string;
    status: TaskTagStatus;
    constructor(props: any = {}) {
        this._id = props._id || '';
        this.name = props.name || '';
        this.description = props.description || '';
        this.color = props.color || '';
        this.point = props.point || -1;
        this.value = props.value || -1;
        this.createdAt = props.createdAt || -1;
        this.updatedAt = props.updatedAt || -1;
        this.userGroupId = props.userGroupId || ''; 
        this.status = props.status !== 0 ? TaskTagStatus.ACTIVE : TaskTagStatus.INACTIVE;
    }

    toObject() {
        return {
            ...this,
            _id: !this._id ? undefined : this._id
        }
    }
}
