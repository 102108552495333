const initState = {
    projects: []
};

const Credit = (state = initState, action: any) => {
    switch (action.type) {
        case 'SET_CREDIT':
            return {
                ...state,
                poolCredit: action.data.poolCredit,
                surplusPoolCredit: action.data.surplusPoolCredit,
                projects: action.data.project
            };
        case 'UPDATE_SURPLUS_POOL_CREDIT':
            return {
                ...state,
                surplusPoolCredit: action.data
            }
        default:
            return state;
    }
};

export default Credit;
