import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import TaskTag from "../../shared/models/taskTag";
import Url from "../../util/url";
import { getALLTaskTagFailed, getALLTaskTagSuccess, ITaskTagAction, TaskTagTypes, updateTaskTagFailed } from "../actions/taskTag.action";

async function getALLTaskTagAPI(): Promise<TaskTag[]> {
    return await axios.get(Url("task-tag")).then((data) => {
        if(data.status == 200 && data.data) {
            return data.data.map((item: any) => {
                return new TaskTag(item);
            });
        }
        return [];
    });
}

async function updateTaskTagAPI(taskTag: TaskTag): Promise<TaskTag | null> {
    return await axios.patch(Url("task-tag"), { data: taskTag }).then((data) => {
        if(data.status == 200 && data.data) {
            return new TaskTag(data.data);
        }
        return null;
    });
}

function* updateTaskTagSaga(action: ITaskTagAction) {
    if(action.taskTags?.length) {
        try {
            const data: TaskTag | null = yield call(updateTaskTagAPI, action.taskTags[0]);
            console.log('updateTaskTagAPI', data);
            if(data) {
                yield put(getALLTaskTagSuccess([data]));
            } else {
                yield put(updateTaskTagFailed("updateTaskTagFailed undefined!"));
            }
        } catch (err) {
            yield put(updateTaskTagFailed("updateTaskTagFailed ERROR: " + err));
        }
    }
}

function* getALLTaskTagSaga() {
    try {
        const taskTags: TaskTag[] | undefined | null = yield select((state: any) => state.taskTagState.taskTags);
        if(taskTags?.length) {
            yield put(getALLTaskTagSuccess(taskTags));
            return;
        }
        const data: TaskTag[] = yield call(getALLTaskTagAPI);
        if(data) {
            yield put(getALLTaskTagSuccess(data));
        } else {
            yield put(getALLTaskTagFailed("getALLTaskTagFailed undefined!"));
        }
    } catch (err) {
        yield put(getALLTaskTagFailed("getALLTaskTagFailed ERROR: " + err));
    }
}

export function* watchTaskTag() {
    yield takeLatest(TaskTagTypes.getALLTaskTag, getALLTaskTagSaga);
    yield takeLatest(TaskTagTypes.updateTaskTag, updateTaskTagSaga);
}
