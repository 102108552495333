import { ITask } from "../../shared/models/task";

export enum NewTaskTypes {
    getTaskBySprintId = "getTaskBySprintId",
    getTaskBySprintIdSuccess = "getTaskBySprintIdSuccess",
    getTaskBySprintIdFailed = "getTaskBySprintIdFailed",
    updateTasks = "updateTasks",
    updateTasksSuccess = "updateTasksSuccess",
    updateTasksFailed = "updateTasksFailed",
}
export interface NewTaskAction {
    type: NewTaskTypes;
    tasks?: ITask[];
    oldTasks?: ITask[];
    error?: any;
    sprintId?: string;
}

export const getTaskBySprintId = (sprintId: string): NewTaskAction => {
    return {
        type: NewTaskTypes.getTaskBySprintId,
        sprintId,
    };
};

export const getTaskBySprintIdSuccess = (tasks: ITask[]): NewTaskAction => {
    return {
        type: NewTaskTypes.getTaskBySprintIdSuccess,
        tasks,
    };
};

export const getTaskBySprintIdFailed = (error: any): NewTaskAction => {
    return {
        type: NewTaskTypes.getTaskBySprintIdFailed,
        error,
    };
};

export const updateTasks = (tasks: ITask[], oldTasks: ITask[]): NewTaskAction => {
    return {
        type: NewTaskTypes.updateTasks,
        tasks,
        oldTasks,
    };
};

export const updateTasksSuccess = (tasks: ITask[]): NewTaskAction => {
    return {
        type: NewTaskTypes.updateTasksSuccess,
        tasks,
    };
};

export const updateTasksFailed = (error: any): NewTaskAction => {
    return {
        type: NewTaskTypes.updateTasksFailed,
        error,
    };
};