interface TaskScoresField {
    value: number;
    label: string;
}
export interface ITaskScores {
    tasksDone: TaskScoresField;
    pointDone: TaskScoresField;
    totalTaskInSprint: TaskScoresField;
    totalTaskDeadline: TaskScoresField;
    taskDoneInDeadline: TaskScoresField;
    expTask: TaskScoresField;
    totalTasks: TaskScoresField;
    taskDonePercentage: TaskScoresField;
    pointDonePercentage: TaskScoresField;
    taskDoneInDeadlinePercentage: TaskScoresField;
    doneRate: TaskScoresField;
    bug?: TaskScoresField;
    totalPoint?: TaskScoresField;
}

export class TaskScore implements ITaskScores {
    tasksDone: TaskScoresField;
    pointDone: TaskScoresField;
    totalTaskDeadline: TaskScoresField;
    totalTaskInSprint: TaskScoresField;
    taskDoneInDeadline: TaskScoresField;
    expTask: TaskScoresField;
    totalTasks: TaskScoresField;
    taskDonePercentage: TaskScoresField;
    pointDonePercentage: TaskScoresField;
    taskDoneInDeadlinePercentage: TaskScoresField;
    doneRate: TaskScoresField;
    bug: TaskScoresField;
    totalPoint: TaskScoresField;
    constructor(props: any = {}) {
        this.tasksDone = {
            value: props.tasksDone ?? 0,
            label: "Số task hoàn thành",
        };
        this.pointDone = {
            value: props.pointDone ?? 0,
            label: "Số point hoàn thành",
        };
        this.totalTaskInSprint = {
            value: props.totalTaskInSprint ?? 0,
            label: "Số lượng task trong sprint",
        };
        this.taskDoneInDeadline = {
            value: props.taskDoneInDeadline ?? 0,
            label: "Số task hoàn thành đúng deadline",
        };
        this.expTask = { value: props.expTask ?? 0, label: "Điểm kinh nghiệm" };
        this.taskDonePercentage = {
            value: props.taskDonePercentage ?? 0,
            label: "Tỷ lệ hoàn thành task",
        };
        this.totalTasks = {
            value: props.totalTasks ?? 0,
            label: "Số lượng task",
        };
        this.pointDonePercentage = {
            value: props.pointDonePercentage ?? 0,
            label: "Tỷ lệ point hoàn thành",
        };
        this.taskDoneInDeadlinePercentage = {
            value: props.taskDoneInDeadlinePercentage ?? 0,
            label: "Tỷ lệ hoàn thành task đúng deadline",
        };
        this.doneRate = {
            value: props.doneRate ?? 0,
            label: "Tỷ lệ hoàn thành",
        };
        this.bug = {
            label: "Bug",
            value: props.bug ?? 0,
        };
        this.totalPoint = {
            label: "Tổng số point",
            value: props.totalPoint,
        };
        this.totalTaskDeadline = {
            label: "Tổng số task deadline",
            value: props.totalTaskDeadline,
        };
    }
}
