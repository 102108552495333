import { all } from "redux-saga/effects";
import { watchGetManageDevice } from "../components/home/ManageDevice/sagas/manageDevice.saga";
import {
    watchCreateUserFeedback,
    watchDeleteUserFeedback,
    watchUpdateUserFeedback,
} from "../components/user/evaluation-modal/saga";
import { watchCreateCareerEvent, watchDeleteCareerEvent, watchUpdateCareerEvent } from "../components/user/event-modal/saga";
import { watchApp } from "./sagas/app.saga";
import { watchAuth } from "./sagas/auth.saga";
import { watchCampaign } from "./sagas/campaign.saga";
import { watchComment } from "./sagas/comment.saga";
import { watchDashBoard } from "./sagas/dashboard.saga";
import { watchGitHub } from "./sagas/github.saga";
import { watchProject } from "./sagas/project.saga";
import { watchRating } from "./sagas/rating.saga";
import { watchReport } from "./sagas/report.saga";
import { watchGetSetting } from "./sagas/setting.saga";
import { watchGetSharing } from "./sagas/sharing.saga";
import { watchSprint } from "./sagas/sprint.saga";
import { watchStatus } from "./sagas/status.saga";
import { watchTask } from "./sagas/task.saga";
import { watchGetTests } from "./sagas/testCase.saga";
import { watchGetCycles } from "./sagas/testCycle.saga";
import { watchGetTestSection } from "./sagas/testSection.saga";
import { watchToolAnalysis } from "./sagas/toolAnalysis.saga";
import { watchUserGroup } from "./sagas/userGroup.saga";
import { watchUserInfo } from "./sagas/userInfo.saga";
import { watchUserProgress } from "./sagas/userProgress.saga";
import { watchGetWorkingTime } from "./sagas/workingTime.saga";

import { watchAppChannel } from "./sagas/appChannel.saga";
import { watchAppTheme } from "./sagas/appTheme.saga";
import { watchAppVersion } from "./sagas/appVersion.saga";
import { watchNotification } from "./sagas/notification_hero.saga";
import { watchPersonalsGoal } from "./sagas/personalsGoal.saga";
import { watchProblemReportingSaga } from "./sagas/problemReporting.saga";
import { watchAppStatistics } from "./sagas/ratingApp.saga";
import { watchTaskTag } from "./sagas/taskTag.saga";
import { watchUserExpectation } from "./sagas/userExpectation.saga";
export default function* rootSaga() {
    yield all([
        watchGetCycles(),
        watchCreateUserFeedback(),
        watchUpdateUserFeedback(),
        watchDeleteUserFeedback(),
        watchCreateCareerEvent(),
        watchUpdateCareerEvent(),
        watchDeleteCareerEvent(),
        watchGetTests(),
        watchGetSetting(),
        watchGetManageDevice(),
        watchGetTestSection(),
        watchDashBoard(),
        watchGetWorkingTime(),
        watchUserInfo(),
        watchAuth(),
        watchTask(),
        watchProject(),
        watchSprint(),
        watchStatus(),
        watchCampaign(),
        watchComment(),
        watchUserExpectation(),
        watchUserProgress(),
        watchReport(),
        watchGitHub(),
        watchUserGroup(),
        watchRating(),
        watchToolAnalysis(),
        watchApp(),
        watchGetSharing(),
        watchAppVersion(),
        watchPersonalsGoal(),
        watchNotification(),
        watchAppTheme(),
        watchProblemReportingSaga(),
        watchAppChannel(),
        watchAppStatistics(),
        watchTaskTag()
    ]);
}
