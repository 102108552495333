import { ActionMeta } from "react-select";
import { ICampaign } from "../shared/models/campaign";
import Config from "../shared/models/config";
import { IPersonalsGoal } from "../shared/models/personalsGoal";
import { IProject } from "../shared/models/project";
import { IFailedReason } from "../shared/models/setting";
import { ISprint } from "../shared/models/sprint";
import { IStatus } from "../shared/models/status";
import { ITask, Task } from "../shared/models/task";
import { ICoefficients, IUserInfo, UserCoefficient } from "../shared/models/userInfo";
import { ITaskScores, TaskScore } from "../shared/models/taskScore";
import {
    calculateDisciplinePoints,
    calculatePercentage,
    calculateTaskScoreFromSprintReport,
    checkTasksInSprint,
    roleForPoint,
} from "../shared/utils";
import { IOffDaysWfhDays } from "../shared/models/offDaysWfhDays";
import { IWorkingTime } from "../shared/models/workingTime";
import { ISprintReport, ISprintReportItem, SprintReport } from "../shared/models/sprintReport";
import { IWfhDayOffDay } from "../shared/models/wfhDayoffDayRegister";
import { getChildTasks } from "../components/dashboard/task-preview";

export interface SelectOption {
    value: string;
    label: string;
    avatar: string;
}

export const onGetOptionsValue = (list: SelectOption[], actionMeta: ActionMeta<SelectOption>) => {
    if (actionMeta.action === "select-option" && actionMeta.option) {
        return [...list, actionMeta.option];
    } else if (actionMeta.action === "remove-value") {
        return list.filter((e) => e.value !== actionMeta.removedValue.value);
    } else if (actionMeta.action === "clear") {
        return [];
    }
    return list;
};

export const convertUserOptions = (list: IUserInfo[]): SelectOption[] => {
    return list.map((u) => {
        return {
            avatar: u.avatar ?? "",
            label: u.firstName + " " + u.lastName,
            value: u._id,
        };
    });
};

export const convertTaskOptions = (list: ITask[]): SelectOption[] => {
    return list.map((u) => {
        return {
            avatar: "",
            label: u.shortId + " - " + u.title,
            value: u._id,
        };
    });
};

export const convertTaskParentOptions = (list: ITask[], task: ITask, listIdSubtask: string[]): SelectOption[] => {
    return list.reduce((options: SelectOption[], u: ITask) => {
        const isIdInSubtasks = listIdSubtask.includes(u._id);// không tự chuyển cho chính subtask muốn chuyển
        const taskMoveId = listIdSubtask.find((t) => t = u._id) // Tìm ra id subTask muốn chuyển
        const taskMove : ITask = list.find((t) => t._id == taskMoveId)! // Tìm ra subTask muốn chuyển
        const isDifferentId = u._id !== task._id; // không tự chuyển cho chính task chuyển
        // let listChildTask = getChildTasks(task,list).map(t => t._id) //Lấy ra tất cả id của task con trong task đang thao tác
        // const isChildTask = listChildTask.includes(u.parentId) // Trường hợp chuyển task con mà không muốn hiển thị task con trong nó
        let listChildMoveTask = getChildTasks(taskMove,list).map(t => t._id)//Lấy ra tất cả id của task con trong task đang thao tác
        const isChildMoveTask = listChildMoveTask.includes(u._id)// Trường hợp chuyển task con mà không muốn hiển thị task con trong nó
        if (isDifferentId && !isIdInSubtasks && !isChildMoveTask  ) {
            options.push({
                avatar: "",
                label: u.shortId + " - " + u.title,
                value: u._id,
            });
        }
        return options;
    }, []);
};

export const convertMergeTaskOptions = (list: ITask[], listIdSubtask: string[]): SelectOption[] => {
    return list.reduce((options: SelectOption[], u: ITask) => {
        const isParentTask = u.type == "Task";
        const isTaskUnduplicated = listIdSubtask.includes(u._id); // Trường hợp chuyển task con mà không muốn hiển thị task con trong nó
        if (!isTaskUnduplicated && isParentTask) {
            options.push({
                avatar: "",
                label: u.shortId + " - " + u.title,
                value: u._id,
            });
        }
        return options;
    }, []);
};

export const convertProjectOptions = (list: IProject[]): SelectOption[] => {
    return list.map((u) => {
        return {
            avatar: "",
            label: u.title,
            value: u._id,
        };
    });
};

export const convertCampaignOptions = (list: ICampaign[]): SelectOption[] => {
    return list.map((u) => {
        return {
            avatar: "",
            label: u.title,
            value: u._id,
        };
    });
};

export const convertFailedReasonOptions = (list: IFailedReason[]): SelectOption[] => {
    return list.map((u) => {
        return {
            avatar: "",
            label: u.name,
            value: u._id,
        };
    });
};
export const hexToRgbA = (hex: string, alpha: number) => {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + alpha + ")";
    }
    // throw new Error("Bad Hex");
};

export const preHandleTasks = (
    tasksData: ITask[],
    users: IUserInfo[],
    sprints: ISprint[],
    allStatus: IStatus[],
    projects: IProject[]
) => {
    let tasks = tasksData;
    tasks = tasks.map((item: any) => {
        let x = new Task(item);
        return x;
    });
    let map: Map<string, ITask> = new Map<string, ITask>();
    for (let task of tasks) {
        map.set(task._id, task);
    }
    for (let task of tasks) {
        if (!task.parent) {
            //chu y phan nay co the co bug vi parent task co the chua duoc gan parent cua no
            if (map.has(task.parentId)) {
                let item = map.get(task.parentId);
                if (item) {
                    task.parent = item;
                }
            }
        }
    }
    tasks = tasks?.filter((t: any) => {
        if (t.type !== "Sub Task") return true;
        //neu la sub task thi check xem co task cha khong!!!
        let tmp = t;
        while (true) {
            if (!tmp.parentId || !tmp.parent) break;
            tmp = tmp.parent;
        }
        if (tmp.type === "Sub Task") return false;
        return true;
    });

    // Gan du lieu thay cho lookup o server
    for (const task of tasks) {
        const assignee = users.find((u) => u._id === task.assigneeId);
        if (assignee) {
            task.assignee = assignee;
        }

        const reporter = users.find((u) => u._id === task.reporterId);
        if (reporter) {
            task.reporter = reporter;
        }

        const testers: IUserInfo[] = [];
        task.testerIds.forEach((tId) => {
            const tester = users.find((u) => u._id === tId);
            if (tester) testers.push(tester);
        });
        if (testers) {
            task.testers = testers;
        }

        const leadDev = users.find((u) => u._id === task.leadDevId);
        if (leadDev) {
            task.leadDev = leadDev;
        }

        const sprint = sprints.find((s) => s._id === task.sprintId);
        if (sprint) {
            task.sprint = sprint;
        }

        const status = allStatus.find((s) => s._id === task.statusId);
        if (status) {
            task.status = status;
        }

        const owner = projects.find((p) => p._id === task.projectId);
        if (owner) {
            task.owner = owner;
        }
    }

    // console.log("update task : ", tasks[5]);
    return tasks;
};

export const calculateTaskScoreFromTasks = (tasks: ITask[], user: IUserInfo, sprint: ISprint): ITaskScores => {
    let tasksDone = 0;
    let pointDone = 0;
    let expTask = 0;
    let taskDoneInDeadline = 0;
    let bug = 0;
    let totalPoint = 0;
    let totalTaskDeadline = 0;
    let assignedTasksInSprint = tasks.filter((task) => {
        if (task.assigneeId === user._id) {
            if(task.deadline) {
                totalTaskDeadline++;
            }
            if (task.type === "Bug") bug++;
            if (task.point) {
                totalPoint += task.point;
            }
            if (checkTasksInSprint(task, sprint)) {
                //tat ca task con deu co task cha (da xu ly o getAllTaskAPI)
                if (
                    task.status?._id === Config.COLUMN_STATUS.DONE.mongoId ||
                    task.status?._id === Config.COLUMN_STATUS.PROD_VALIDATION.mongoId ||
                    task.checkPoint // task được checkPoint thì tính
                ) {
                    tasksDone++;
                    let timeDone = new Date(task.finishedDate);
                    if (timeDone && !!task.deadline) {
                        if (timeDone.getTime() <= new Date(task?.deadline)?.getTime()) {
                            taskDoneInDeadline++;
                        }
                    }
                    if (task.point) {
                        // point van tinh du miss dl hay khong
                        pointDone += parseFloat(task.point + "");
                        expTask += task.point * task.coef;
                    }
                }
                return true;
            }
        }
        return false;
    });
    let totalTaskInSprint = assignedTasksInSprint.length;
    let totalTasks = tasks?.filter((t) => t.assigneeId === user._id)?.length ?? 0;
    let { taskDonePercentage, pointDonePercentage, taskDoneInDeadlinePercentage, doneRate } = calculatePercentage(
        totalTaskInSprint,
        tasksDone,
        pointDone,
        taskDoneInDeadline,
        totalTaskDeadline
    );
    return new TaskScore({
        tasksDone: tasksDone ?? 0,
        pointDone: pointDone ?? 0,
        totalTaskInSprint: totalTaskInSprint ?? 0,
        taskDoneInDeadline: taskDoneInDeadline ?? 0,
        expTask: expTask ?? 0,
        totalTasks: totalTasks ?? 0,
        taskDonePercentage: taskDonePercentage ?? 0,
        pointDonePercentage: pointDonePercentage ?? 0,
        taskDoneInDeadlinePercentage: taskDoneInDeadlinePercentage ?? 0,
        doneRate: doneRate ?? 0,
        bug: bug,
        totalPoint: totalPoint,
        totalTaskDeadline: totalTaskDeadline ?? 0,
    });
};

export const handleSortPriority = (personalsGoal: IPersonalsGoal[]) => {
    personalsGoal.sort((a, b) => {
        const order: any = {
            Highest: 1,
            High: 2,
            Medium: 3,
            Low: 4,
            Lowest: 5,
        };

        const orderA = order[a.priority] || 6;

        const orderB = order[b.priority] || 6;

        if (orderA < orderB) return -1;

        if (orderA > orderB) return 1;

        return 0;
    });
};
export default function downloadObjectAsJson(data: any, fileName: string) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", fileName);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

export const calculateExp = (
    disciplinePoints: any,
    user: IUserInfo,
    taskScores: ITaskScores,
    coefficients: ICoefficients = new UserCoefficient()
) => {
    let _disciplinePoints = (100 - (disciplinePoints?.minusScore ?? 0)) * coefficients.cDiscipline;
    return {
        prev: user.point - user.currentLevelStartExp,
        current: (taskScores?.expTask.value ?? 0) * coefficients.cWork + _disciplinePoints,
    };
};

export const getColoWithPercent = (percent: number) => {
    if (percent > 0.9) {
        return "chartreuse";
    }
    if (percent > 0.6) {
        return "aquamarine";
    }
    if (percent > 0.4) {
        return "burlywood";
    }
    return "#ff4d4f";
};

export const getUserTaskScores = ({
    tasks,
    currentSprint,
    users,
    newSprintReports,
    onlyReports,
}: {
    tasks?: ITask[];
    currentSprint: ISprint;
    users: IUserInfo[];
    newSprintReports?: any;
    onlyReports?: boolean;
}) => {
    try {
        let data: { [userId: string]: ITaskScores } = {};
        if (currentSprint?.activeSprint && !onlyReports) {
            if (tasks) {
                let mapUserTasks: any = {};
                for (let task of tasks) {
                    if (!mapUserTasks[task.assigneeId]) mapUserTasks[task.assigneeId] = [];
                    mapUserTasks[task.assigneeId].push(task);
                }

                users.forEach((user) => {
                    if (!roleForPoint(user)) return;
                    let taskScores = calculateTaskScoreFromTasks(mapUserTasks[user._id] ?? [], user, currentSprint);
                    data[user._id] = taskScores;
                });
            }
        } else {
            if (newSprintReports) {
                users.forEach((user) => {
                    if (!roleForPoint(user)) return;
                    let sprintData = newSprintReports[user._id]?.find((report: any) => {
                        return report?.sprintId === currentSprint._id;
                    });
                    let taskScores = calculateTaskScoreFromSprintReport(sprintData?.sprintTasks ?? []);
                    data[user._id] = taskScores;
                });
            }
        }
        return data;
    } catch (e) {
        console.log("getUserTaskScores Error: ", e);
        return {};
    }
};
type DayItem = { date: string; reason: string };
export const getUserDisciplineScore = ({
    currentSprint,
    users,
    offDaysWfhDays,
    workingTimes,
    newSprintReports,
    onlyReports,
}: {
    currentSprint: ISprint;
    users: IUserInfo[];
    offDaysWfhDays?: IOffDaysWfhDays[];
    workingTimes?: IWorkingTime[];
    newSprintReports?: any;
    onlyReports?: boolean;
}): {
    [userId: string]: {
        minusScore: number;
        offDaysUnauthorized: DayItem[];
        workingTime: DayItem[];
    };
} => {
    try {
        let listDisciplineScore: any = {};
        if (currentSprint?.activeSprint && !onlyReports) {
            if (offDaysWfhDays && workingTimes) {
                users.forEach((user) => {
                    if (!roleForPoint(user)) return;
                    let score = calculateDisciplinePoints(
                        currentSprint,
                        offDaysWfhDays.find((d) => d.userId === user._id),
                        workingTimes.filter((d) => d.userId === user._id)
                    );
                    listDisciplineScore[user._id] = score;
                });
            }
        } else {
            if (newSprintReports) {
                users.forEach((user) => {
                    if (!roleForPoint(user)) return;
                    let discipline =
                        newSprintReports[user._id]?.find((report: any) => {
                            return report?.sprintId === currentSprint._id;
                        })?.discipline ?? {};
                    let offDaysUnauthorized = [];
                    let workingTime = [];
                    let offDay = 0;
                    if (discipline?.offDaysUnauthorized?.length) {
                        offDaysUnauthorized = discipline?.offDaysUnauthorized?.map((d: any) => {
                            let dayData = d.split(": ");
                            if (dayData[1].indexOf(Config.ALL_DAY_VNESE) >= 0) offDay += 1;
                            else offDay += 0.5;
                            return {
                                date: dayData[0],
                                reason: dayData[1],
                            };
                        });
                    }
                    if (discipline?.workingTime?.length) {
                        workingTime = discipline?.workingTime?.map((d: any) => {
                            let dayData = d.split(": ");
                            let { slot } = getDataFromReason(dayData[1]);
                            return {
                                date: dayData[0],
                                reason: dayData[1],
                                slot: slot,
                            };
                        });
                    }
                    let userDiscipline = {
                        minusScore: discipline?.minusScore ?? 0,
                        offDaysUnauthorized: offDaysUnauthorized,
                        workingTime: workingTime,
                        offDay: offDay,
                    };
                    listDisciplineScore[user._id] = userDiscipline;
                });
            }
        }
        return listDisciplineScore;
    } catch (e) {
        console.log("getUserDisciplineScore Error: ", e);
        return {
            "": { minusScore: 0, offDaysUnauthorized: [], workingTime: [] },
        };
    }
};

export const convertSprintReportV2ItemToSprintReportItem = (
    taskScores: ITaskScores,
    sprint: ISprint,
    user: IUserInfo
): ISprintReport => {
    if (!taskScores) return new SprintReport();
    let totalTasks: ISprintReportItem[] = [];
    let finishedTasks: ISprintReportItem[] = [];
    let bugTasks: ISprintReportItem[] = [];
    let _totalTask = taskScores.totalTasks.value;
    let _bug = taskScores.bug?.value ?? 0;
    let _taskDone = taskScores.tasksDone.value;
    totalTasks = new Array<ISprintReportItem>(_totalTask);
    bugTasks = new Array(_bug);
    finishedTasks = new Array(_taskDone);
    let sprintId = sprint._id;
    let userId = user._id;
    return {
        _id: "",
        totalTasks: totalTasks,
        finishedTasks: finishedTasks,
        bugTasks: bugTasks,
        sprint: sprintId,
        userId: userId,
        user: user,
        lastUpdate: null,
    };
};

export const convertToOffDays = (data: any): IWfhDayOffDay[] => {
    let res: IWfhDayOffDay[] = [];
    try {
        for (let item of data) {
            let day: IWfhDayOffDay = {
                _id: "",
                date: new Date(),
                slot: "",
                reason: "",
                userId: "_empty_",
                type: "",
                offType: "",
            };

            if (!!item?.date) day.date = new Date(item.date);
            day.reason = item.reason;
            let _data = getDataFromReason(item.reason);
            day.slot = item.slot ?? _data.slot;
            day.userId = item.userId;
            day.offType = item.offType ?? _data.offType;
            day.type = Config.TYPE.offday;
            res.push(day);
        }
        return res;
    } catch (e) {
        console.log("convertToOffDays Error: ", e);
        return [];
    }
};

const getDataFromReason = (reason: string) => {
    let slot = Config.ALL_DAY_VNESE,
        offType = Config.OFF_TYPE_AUTHORIZED;
    //"Cả ngày, Nghỉ khẩn cấp mức 1, Do bận việc cá nhân nên em xin nghỉ phép ngày này ạ, trừ 10 điểm"
    let part = reason.split(",").map((p) => p.trim().toLowerCase());
    if (part[0].indexOf(Config.ALL_DAY_VNESE.toLowerCase()) == 0) {
        slot = Config.ALL_DAY_VNESE;
    } else if (part[0].indexOf(Config.MORNING_VNESE.toLowerCase()) == 0) {
        slot = Config.MORNING_VNESE;
    } else if (part[0].indexOf(Config.AFTERNOON_VNESE.toLowerCase()) == 0) {
        slot = Config.AFTERNOON_VNESE;
    }

    if (part[1].toLowerCase().indexOf("không phép") == 0) offType = Config.OFF_TYPE_UNAUTHORIZED.toLowerCase();
    else if (part[1].toLowerCase().indexOf("Nghỉ khẩn cấp mức 1-") == 0) offType = Config.OFF_TYPE_URGENT_1 + "-";
    else if (part[1].toLowerCase().indexOf("Nghỉ khẩn cấp mức 2-") == 0) offType = Config.OFF_TYPE_URGENT_2 + "-";
    else if (part[1].toLowerCase().indexOf("Nghỉ khẩn cấp mức 1") == 0) offType = Config.OFF_TYPE_URGENT_1;
    else if (part[1].toLowerCase().indexOf("Nghỉ khẩn cấp mức 2") == 0) offType = Config.OFF_TYPE_URGENT_2;
    return { slot, offType };
};
