export const MANAGE_DEVICE_TYPE = {
    GET_ALL_MANAGE_DEVICE: 'GET_ALL_MANAGE_DEVICE',
    GET_ALL_MANAGE_DEVICE_SUCCESS: 'GET_ALL_MANAGE_DEVICE_SUCCESS',
    GET_ALL_MANAGE_DEVICE_FAILED: 'GET_ALL_MANAGE_DEVICE_FAILED',
    ADD_MANAGE_DEVICE: 'ADD_MANAGE_DEVICE',
    UPDATE_MANAGE_DEVICES: 'UPDATE_MANAGE_DEVICES',
    DELETE_MANAGE_DEVICE: 'DELETE__MANAGE_DEVICE'
}


const initState = {
    data: [],
    loading: false,
    error: false,
}

const manageDeviceReducer = (state = initState, action: any) => {
    switch (action.type){
        case MANAGE_DEVICE_TYPE.GET_ALL_MANAGE_DEVICE:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case MANAGE_DEVICE_TYPE.GET_ALL_MANAGE_DEVICE_SUCCESS: 
            return {
                data: action.data,
                loading: false,
                error: false,
            }
        case MANAGE_DEVICE_TYPE.GET_ALL_MANAGE_DEVICE_FAILED:
            return {
                loading: false,
                error: true,
            }
        case MANAGE_DEVICE_TYPE.ADD_MANAGE_DEVICE: 
            return {
                ...state,
                data: [...state.data, action.data]
            }
        case MANAGE_DEVICE_TYPE.UPDATE_MANAGE_DEVICES:
            let tempArr: any[] = [...state.data];
            tempArr = tempArr.map((item: any) => {
                const manageDevice = action.data.find((md: any) => md._id === item._id);
                if(manageDevice) {
                    return manageDevice;
                }

                return item;
            })
            return {
                ...state,
                data: tempArr
            }

        case MANAGE_DEVICE_TYPE.DELETE_MANAGE_DEVICE: 
            let tempArr1: any[] = state.data.filter((item: any) => action.data._id !== item._id);

            return {
                ...state,
                data: tempArr1
            }

        default:
            return state;
    }
}

export default manageDeviceReducer;