import Button from "@mui/material/Button";
import { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAllCampaign } from "../../redux/actions/campaign.action";
import { getTasksByIds } from "../../redux/actions/task.action";
import { ICampaignState } from "../../redux/reducers/campaign_new.reducer";
import { ITaskState } from "../../redux/reducers/task.reducer";
// import { getTaskAPI } from "../../redux/sagas/task.saga";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import Collapse from "@mui/material/Collapse";
import { toast } from "react-toastify";
import Constants from "../../constant";
import { ISprint } from "../../shared/models/sprint";
import { IUserGroup } from "../../shared/models/userGroup";
import { IUserInfo } from "../../shared/models/userInfo";
import { isLeaderEditPoint } from "../../shared/permissions";
import { converDataCampaign, isAdmin } from "../../util";
import LoadingWidget from "../LoadingWidget";
import CampaignProgressWidget from "./campaign/campaign-progress";
import { DashboardProps } from "./dashboardV2";
import MergeTaskWidget from "./merge-task";
import DashboardProjectsFilter from "./projects-filter";
import SelectFilter, { SelectFilterItem } from "./projects-filter/select-filter";
import DashboardSprintColumnsV2 from "./sprint-columns/DashboardSprintColumnsV2";
import "./style.scss";
import DashboardUsersFilter from "./users-filter/UserFilterWidget";

const DashboardTaskView: FC<
    DashboardProps & {
        currentSprint: ISprint;
        currentUserIds: string[];
        setCurrentUserIds: any;
        userTaskScore: any;
    }
> = memo(
    ({ projects, currentSprint, currentUserIds, setCurrentUserIds, userTaskScore }) => {
        const history = useHistory();
        const campaignState: ICampaignState = useSelector((state: any) => state.campaignState);
        const [selectedFilters, setSelectedFilters] = useState<SelectFilterItem[]>([]);
        const [showAllTasks, setShowAllTasks] = useState(
            !!localStorage.getItem("showAllTasks") ? (localStorage.getItem("showAllTasks") == "true" ? true : false) : false
        );
        const [showButtonArchiveCampaign, setShowButtonArchiveCampaign] = useState(false);
        const [openArchive, setOpenArchive] = useState(false);
        const [showModalMergeTask, setShowModalMergeTask] = useState(false);
        const [showCheckBox, setShowCheckBox] = useState(false);
        const [listId, setListId] = useState<string[]>([]);
        const getSearchParams = () => {
            let searchParams = new URLSearchParams(history.location.search);
            let userStr = searchParams.get("user");
            let projectStr = searchParams.get("project");
            return {
                userIds: userStr?.split(",") ?? [],
                projectIds: projectStr?.split(",") ?? [],
            };
        };
        const campaignTasks = useSelector((state: any) => state.taskState.campaignTasks);
        const user = useSelector((state: any) => state.authState.user);
        const filterProjectWithSearchParams = (ids: string[]): string[] => {
            // chuyển tên project thành id và bỏ những tên hoặc id không có trong projects
            return ids
                .map((id) => {
                    return projects.find((p) => p._id == id || p.title == id)?._id ?? "";
                })
                .filter((id) => !!id);
        };

        const userGroups: IUserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
        const searchParams = getSearchParams();
        const [currentProjectIds, setCurrentProjectIds] = useState<string[]>(
            filterProjectWithSearchParams(searchParams.projectIds)
        );

        const pushSearchParams = (projectIds: string[], userIds: string[]) => {
            if (projectIds.length && userIds.length) {
                history.push(`?user=${userIds.join(",")}&project=${projectIds.join(",")}`);
            } else if (projectIds.length && !userIds.length) {
                history.push(`?project=${projectIds.join(",")}`);
            } else if (!projectIds.length && userIds.length) {
                history.push(`?user=${userIds.join(",")}`);
            } else {
                history.push(window.location.pathname); /////////////////////////////////////// check
            }
        };
        const onSelectedProject = (id: string) => {
            let newList = [...currentProjectIds];
            let index = newList.indexOf(id);
            if (index == -1) {
                newList.push(id);
            } else {
                newList.splice(index, 1);
            }
            setCurrentProjectIds(newList);
            pushSearchParams(newList, currentUserIds);
        };
        const loadCampaignTasks = async (campaignIds: string[]) => {
            let campaigns = campaignIds
                .map((c) => {
                    if (!campaignTasks[c]) {
                        let campaign = campaignState?.campaigns?.find((cp) => cp._id === c);
                        if (campaign) {
                            return campaign;
                        }
                    }
                    return null;
                })
                .filter((c) => c);
            let { listTaskIds, campaignObj } = converDataCampaign(campaigns);
            dispatch(getTasksByIds(listTaskIds, campaignObj));
        };
        const onSelectedUser = (id: string) => {
            let newList = [...currentUserIds];
            let index = newList.indexOf(id);
            if (index == -1) {
                newList.push(id);
            } else {
                newList.splice(index, 1);
            }
            setCurrentUserIds(newList);
            pushSearchParams(currentProjectIds, newList);
        };
        const clearFilter = () => {
            setCurrentProjectIds([]);
            setCurrentUserIds([]);
            pushSearchParams([], []);
            setForceCurrentCampaignIds(null);
            setSelectedFilters([]);
        };
        // hiện thị những task có currentTaskIds, nếu currentTaskIds trống thì hiện hết
        // const [currentTaskIds, setCurrentTaskIds] = useState<string[]>([]);
        // hiện thị những task theo campaign
        const [forceCurrentCampaignIds, setForceCurrentCampaignIds] = useState<string[] | null>(null);
        const _displayedCampaign = localStorage.getItem("showCampaigns")?.startsWith("true") ?? false;
        const dispatch = useDispatch();
        useEffect(() => {
            if (!_displayedCampaign && !campaignState.campaigns.length) {
                // da luu roi thi khong can load lai nua, neu co update thi cung co update truc tiep vao redux luon, chi can load lan dau vao trang
                dispatch(getAllCampaign());
            }
        }, [_displayedCampaign]);
        const getMemberInGroupWhenImLead = (userGroups: IUserGroup[], user: IUserInfo) => {
            let setMemberGroupIds = new Set<string>();
            userGroups.forEach((group) => {
                let isLeader = false;
                group.users.forEach((u) => {
                    if (u.role === "lead" && u.userId === user._id) {
                        isLeader = true;
                    }
                });
                if (isLeader) {
                    group.users.forEach((u) => {
                        if (u.role !== "lead") setMemberGroupIds.add(u.userId);
                    });
                }
            });
            // if (setMemberGroupIds.size > 0) ///////////////////////////////// nếu sau có vđề gì về việc hiện thanh user owr member thì chính là chỗ này nha
            setMemberGroupIds.add(user._id);
            return setMemberGroupIds;
        };
        const [activeCampains, setActiveCampains] = useState(_displayedCampaign);
        const setMemberGroupIds: Set<string> = getMemberInGroupWhenImLead(userGroups, user);
        const loadingTasks: ITaskState = useSelector((state: any) => state.taskState.loading);
        if (loadingTasks) {
            return <LoadingWidget />;
        }
        const onHide = () => {
            setShowModalMergeTask(false);
        };
        return (
            <div>
                <div className="main-body">
                    <div>
                        <SelectFilter
                            value={selectedFilters}
                            onChange={setSelectedFilters}
                            setShowAllTasks={() => {
                                setShowAllTasks(!showAllTasks);
                                localStorage.setItem("showAllTasks", showAllTasks ? "false" : "true");
                            }}
                            showAllTasks={showAllTasks}
                        />
                        {isLeaderEditPoint(user) && (
                            <div>
                                <div className={!(listId.length > 0) ? "btn-merge-task" : "btn-click-merge-task"}>
                                    {!(listId.length > 0) ? (
                                        <Button
                                            onClick={() => {
                                                setShowCheckBox(true);
                                                if (!(listId.length > 0)) {
                                                    toast("Vui lòng chọn task muốn merge !", { position: "top-center" });
                                                }
                                            }}
                                        >
                                            <span>Merge Task</span>
                                        </Button>
                                    ) : (
                                        <Button style={{ marginLeft: "15px" }} onClick={() => setShowModalMergeTask(true)}>
                                            <span>Merge</span>
                                        </Button>
                                    )}
                                </div>
                                <div className="btn-merge-task">
                                    {showCheckBox && (
                                        <Button style={{ marginLeft: "15px" }} onClick={() => setShowCheckBox(false)}>
                                            <span>Hủy</span>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="background-color-2 radius-12 align-item-center column">
                            <div className={"body-grid-area campaigns " + (activeCampains ? "active-campaigns" : "")}>
                                <div className="background-color-2">
                                    <Button
                                        variant="text"
                                        className="show-campaigns-button"
                                        onClick={() => {
                                            setActiveCampains(!activeCampains);
                                            localStorage.setItem("showCampaigns", !activeCampains + "");
                                        }}
                                    >
                                        <span>Campaigns</span>
                                    </Button>
                                </div>
                            </div>
                            <div className="wrap-icon-archive">
                                <Collapse in={showButtonArchiveCampaign}>
                                    <div
                                        className="justify-content-center"
                                        style={{
                                            cursor: "pointer",
                                            marginTop: "8px",
                                        }}
                                        onClick={() => {
                                            setOpenArchive(!openArchive);
                                        }}
                                    >
                                        <Inventory2RoundedIcon htmlColor={openArchive ? "#fff" : "rgba(255,255,255,0.6)"} />
                                    </div>
                                </Collapse>
                                <img
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setShowButtonArchiveCampaign(!showButtonArchiveCampaign);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    src={showButtonArchiveCampaign ? "/hide-campaign.svg" : "/show-campaign.svg"}
                                />
                            </div>
                        </div>

                        <div className="body-grid-area projects-filter" style={{ cursor: "pointer" }} onClick={() => {}}>
                            <DashboardProjectsFilter
                                currentProjectIds={currentProjectIds}
                                onSelectedProject={onSelectedProject}
                                isNewVersion={true}
                            />
                        </div>
                    </div>

                    <div className="body-grid-area tasks">
                        {setMemberGroupIds.size > 0 || isAdmin(user) ? (
                            <div className="filter-user-container" style={{ marginBottom: "5px" }}>
                                <DashboardUsersFilter
                                    currentUserIds={currentUserIds}
                                    onSelectedUser={onSelectedUser}
                                    setMemberGroupIds={setMemberGroupIds}
                                    currentSprintId={currentSprint._id}
                                    userTaskScore={userTaskScore}
                                />
                            </div>
                        ) : (
                            <div style={{ height: "66px" }}></div>
                        )}
                        {activeCampains && (
                            <div style={{ marginBottom: "16px" }}>
                                <CampaignProgressWidget
                                    currentSprint={currentSprint}
                                    currentProjectIds={currentProjectIds}
                                    onShowTaskIds={(campaignIds: string[], onReload?: boolean) => {
                                        if (!onReload) {
                                            clearFilter();
                                        }
                                        if (campaignIds?.length) {
                                            loadCampaignTasks(campaignIds);
                                            history.push(`?${Constants.SHOW_CAMPAIGN_TASKS}=${campaignIds.join(",")}`);
                                        } else history.push(window.location.pathname);
                                        setForceCurrentCampaignIds(campaignIds.length ? campaignIds : null);
                                    }}
                                    archive={openArchive}
                                />
                            </div>
                        )}
                        <DashboardSprintColumnsV2
                            currentProjectIds={currentProjectIds}
                            currentUserIds={currentUserIds}
                            showAllTasks={showAllTasks}
                            forceCurrentCampaignIds={forceCurrentCampaignIds}
                            selectedFilters={selectedFilters}
                            setMembersInGroup={Array.from(setMemberGroupIds)}
                            isShowCheckBox={showCheckBox}
                            handleGetIds={setListId}
                        />
                    </div>
                </div>
                {showModalMergeTask && <MergeTaskWidget onHide={onHide} listId={listId} setShowCheckBox={setShowCheckBox} />}
            </div>
        );
    },
    (prevProps, nextProps) => {
        if (
            JSON.stringify(prevProps.projects)?.localeCompare(JSON.stringify(nextProps.projects)) != 0 ||
            JSON.stringify(prevProps.currentSprint._id)?.localeCompare(JSON.stringify(nextProps.currentSprint._id)) != 0 ||
            JSON.stringify(prevProps.currentUserIds)?.localeCompare(JSON.stringify(nextProps.currentUserIds)) != 0 ||
            JSON.stringify(prevProps.userTaskScore)?.localeCompare(JSON.stringify(nextProps.userTaskScore)) != 0
        ) {
            // console.log("re-render");
            return false;
        }
        return true;
    }
);

export default DashboardTaskView;