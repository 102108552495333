const initState = {
    loading: false,
    data: [],
};

const Feedback = (state = initState, action: any) => {
    switch (action.type) {
        case "SET_USER_FEEDBACK":
            return {
                ...state,
                data: action.data,
            };
        case "CREATE_USER_FEEDBACK":
            return {
                ...state,
                loading: true,
            };
        case "CREATE_USER_FEEDBACK_SUCCESS":
            return {
                loading: false,
                data: state.data.concat(action.data),
            };
        case "CREATE_USER_FEEDBACK_FAILED":
            return {
                ...state,
                loading: false,
            };
        case "UPDATE_USER_FEEDBACK":
            return {
                ...state,
                loading: true,
            };
        case "UPDATE_USER_FEEDBACK_SUCCESS":
            return {
                loading: false,
                data: state.data.map((feedback: any) =>
                    feedback._id === action.data._id ? action.data : feedback
                ),
            };
        case "UPDATE_USER_FEEDBACK_FAILED":
            return {
                ...state,
                loading: false,
            };
        case "DELETE_USER_FEEDBACK":
            return {
                ...state,
                loading: true,
            };
        case "DELETE_USER_FEEDBACK_SUCCESS":
            return {
                loading: false,
                data: state.data.filter(
                    (feedback: any) => feedback._id !== action.data
                ),
            };
        case "DELETE_USER_FEEDBACK_FAILED":
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default Feedback;
