import TaskTag from "../../shared/models/taskTag";
import { ITaskTagAction, TaskTagTypes } from "../actions/taskTag.action";

export interface ITaskTagState {
	loading: boolean
	taskTags?: TaskTag[]
	error?: any
}

const initState: ITaskTagState = {
	loading: false,
};

const taskTagState = (state = initState, action: ITaskTagAction): ITaskTagState => {
	switch (action.type) {
		case TaskTagTypes.getALLTaskTag:
			return {
				...state,
				... {
					loading: true,
				}
			}
		case TaskTagTypes.getALLTaskTagSuccess:
			if(action.taskTags) {
				if(!state.taskTags) {
					state.taskTags = []
				}
				for(const item of action.taskTags) {
					if(state.taskTags.find((tag) => tag._id === item._id)) {
						continue;
					}
					state.taskTags.push(item);
				}
			}
			return {
				...state,
				...{
					loading: false,
				}
			}
		case TaskTagTypes.getALLTaskTagFailed:
			return {
				...state,
				...{
					loading: false,
					error: action.error,
				}
			}
		default:
			return state;
	}
};

export default taskTagState;
