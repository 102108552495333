import { combineReducers } from "redux";
import filterReducer from "../../components/dashboard-analytic/Filter/filter.reducer";
import resultReducer from "../../components/dashboard-analytic/Result/result.reducer";
import appState from "./app.reducer";
import appThemeState from "./appTheme.reducer";
import appThemeRootState from "./appThemeRoot.reducer";
import appVersionState from "./appVersion.reducer";
import authState, { IAuthState } from "./auth.reducer";
// import CampaignReducer from "./campaign.reducer";
import campaignState, { ICampaignState } from "./campaign_new.reducer";
import CareerEvent from "./career-event.reducer";
import commentState, { ICommentState } from "./comment.reducer";
import Credit from "./credit.reducer";
import dashBoardState from "./dashboard.reducer";
import Feedback from "./feedback.reducer";
import gitHubState from "./github.reducer";
import HeaderInfo, { IHeaderInfo } from "./headerInfo.reducer";
import UserInfoReducer from "./infoUser.reducer";
import Item from "./item.reducer";
import manageDeviceReducer from "./manageDevice.reducer";
import MyProfile from "./myProfile.reducer";
import MyItem from "./myitem.reducer";
import Notifications from "./notification.reducer";
import notificationState from "./notification_hero.reducer";
import Page from "./page.reducer";
import personalsGoalState from "./personalGoal.reducer";
import Point from "./point.reducer";
import problemReportingState from "./problemReporting.reducer";
import projectState, { IProjectState } from "./project.reducer";
import ratingState, { IRatingState } from "./rating.reducer";
import appStatisticsState from "./ratingApp.reducer";
import reportState, { IReportState } from "./report.reducer";
import Request from "./request.reducer";
import rewardMinusExpState from "./rewardMinusExp.reducer";
import SearchTasksInput from "./searchTasksInput.reducer";
import settingState from "./setting.reducer";
import sharingState from "./sharing.reducer";
import SprintProjectsReducer from "./sprint-projects.reducer";
import SprintStatusesReducer from "./sprint-statuses.reducer";
import sprintState, { ISprintState } from "./sprint.reducer";
import sprintReportState, { ISprintReportState } from "./sprintReport.reducer";
import SprintsReducer from "./sprints.reducer";
import statusState, { IStatusState } from "./status.reducer";
import taskState, { ITaskState } from "./task.reducer";
import testsReducer, { ITestCaseState } from "./testCase.reducer";
import cyclesReducer, { ICyclesState } from "./testCycle.reducer";
import testSectionReducer, { ITestSectionState } from "./testSection.reducer";
import toolAnalysisState from "./toolAnalysis.reducer";
import User from "./user.reducer";
import userExpectationState, { IUserExpectationState } from "./userExpectation.reducer";
import userGroupState, { IUserGroupState } from "./userGroup.reducer";
import userInfoState, { IUserInfoState } from "./userInfo.reducer";
import UserPermissions from "./userPermissions.reducer";
import userProgressState from "./userProgress.reducer";
import webReducer from "./web.reducer";
import WorkingTimeReducer from "./workingTime.reducer";

import appChannelState from "./appChannel.reducer";
import newTaskState, { NewTaskState } from "./newTask.reducer";
import taskTagState, { ITaskTagState } from "./taskTag.reducer";

export interface RootState {
    tests: ITestCaseState,
    cycles: ICyclesState,
    setPoint: any,
    testSections: ITestSectionState,
    item: any,
    request: any,
    notifications: any,
    page: any,
    userbonus: any,
    myitem: any,
    user: any,
    userInfo: any,
    myProfile: any,
    headerInfo: IHeaderInfo,
    credit: any,
    feedback: any,
    event: any,
    sprintStatuses: any,
    sprintProjects: any,
    sprints: any,
    webReducer: any,
    userPermissions: any,
    filterReducer: any,
    resultReducer: any,
    settingState: any,
    dashBoardState: any,
    manageDevice: any,
    workingTime: any,
    searchTasksInput: any,
    sprintReportState: ISprintReportState,
    userInfoState: IUserInfoState,
    authState: IAuthState,
    taskState: ITaskState,
    statusState: IStatusState,
    projectState: IProjectState,
    sprintState: ISprintState,
    campaignState: ICampaignState,
    commentState: ICommentState,
    userExpectationState: IUserExpectationState,
    userProgressState: any,
    reportState: IReportState,
    gitHubState: any,
    userGroupState: IUserGroupState,
    ratingState: IRatingState,
    toolAnalysisState: any,
    appState: any,
    appVersionState: any,
    sharingState: any,
    rewardMinusExpState: any,
    personalsGoalState: any,
    notificationState: any,
    appThemeState: any,
    appThemeRootState: any,
    problemReportingState: any,
    appChannelState: any,
    appStatisticsState: any,
    taskTagState: ITaskTagState,
    newTaskState: NewTaskState
}

export default combineReducers<RootState>({
    tests: testsReducer,
    cycles: cyclesReducer,
    setPoint: Point,
    testSections: testSectionReducer,
    item: Item,
    request: Request,
    notifications: Notifications,
    page: Page,
    userbonus: User,
    myitem: MyItem,
    user: UserInfoReducer,
    userInfo: User,
    myProfile: MyProfile,
    headerInfo: HeaderInfo,
    credit: Credit,
    feedback: Feedback,
    event: CareerEvent,
    sprintStatuses: SprintStatusesReducer,
    sprintProjects: SprintProjectsReducer,
    sprints: SprintsReducer,
    webReducer,
    userPermissions: UserPermissions,
    filterReducer: filterReducer,
    resultReducer: resultReducer,
    settingState,
    dashBoardState,
    // campaigns: CampaignReducer,
    manageDevice: manageDeviceReducer,
    workingTime: WorkingTimeReducer,
    searchTasksInput: SearchTasksInput,
    sprintReportState,
    userInfoState,
    authState,
    taskState,
    statusState,
    projectState,
    sprintState,
    campaignState,
    commentState,
    userExpectationState,
    userProgressState,
    reportState,
    gitHubState,
    userGroupState,
    ratingState,
    toolAnalysisState,
    appState,
    appVersionState,
    sharingState,
    rewardMinusExpState,
    personalsGoalState,
    notificationState,
    appThemeState,
    appThemeRootState,
    problemReportingState,
    appChannelState,
    appStatisticsState,
    taskTagState,
    newTaskState
});
