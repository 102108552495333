import { ISprintReport } from "../../shared/models/sprintReport";
import { IUserInfo } from "../../shared/models/userInfo";

export interface IHeaderInfo {
  user: IUserInfo | null;
  sprintReport: ISprintReport[];
  sprintTime: {
    startDate: number;
    endDate: number;
  };
}
const initState: IHeaderInfo = {
  user: null,
  sprintReport: [],
  sprintTime: {
    startDate: 0,
    endDate: 0
  },
};

const HeaderInfo = (state = initState, action: any) => {
  switch (action.type) {
    case 'SET_HEADER_INFO':
      return action.data;
    case 'UPDATE_CREDIT': 
      return {...state, user: {...state.user, credit: action.data}}
    case 'UPDATE_HEADER_NAME':
      return {...state, user: {...state.user, firstName: action.data.firstName, lastName: action.data.lastName}};
    case 'UPDATE_HEADER_AVATAR':
      return {...state, user: {...state.user, avatar: action.data.avatar}};
    default:
      return state;
  }
}

export default HeaderInfo;