const initState = null;

const UserPermissions = (state = initState, action: any) => {
    switch (action.type) {
        case 'SET_USER_PERMISSIONS':
            return action.data;
        default:
            return state;
    }
};

export default UserPermissions;