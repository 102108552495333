import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSprint } from "../../redux/actions/sprint.action";
import { getAllUserGroup } from "../../redux/actions/userGroup.action";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";
// import { ISprintState } from "../../redux/reducers/sprint.reducer";
import { IUserGroupState } from "../../redux/reducers/userGroup.reducer";
import { IUserInfoState } from "../../redux/reducers/userInfo.reducer";
// import { ISprint } from "../../shared/models/sprint";
import { IUserGroup } from "../../shared/models/userGroup";
import { IUserInfo } from "../../shared/models/userInfo";
// import ErrorWidget from "../ErrorWidget";
import LoadingWidget from "../LoadingWidget";
// import DashboardSidebar from "../dashboard/sidebar";
import Container from "@mui/material/Container";
import { getAllProject } from "../../redux/actions/project.action";
import EditAddUserGroupModal from "./add-update-user-group-modal";
import { IProjectState } from "../../redux/reducers/project.reducer";

const UserGroup = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllUserGroup());
        dispatch(getAllSprint());
        dispatch(getAllUserInfo());
        dispatch(getAllProject());
    }, []);
    const projectState: IProjectState = useSelector((state: any) => state.projectState);
    const userSession: IUserInfo = useSelector((state: any) => state.authState.user);
    // const sprintState: ISprintState = useSelector(
    //     (state: any) => state.sprintState
    // );
    const users: IUserInfoState = useSelector((state: any) => state.userInfoState);
    const userGroupState: IUserGroupState = useSelector((state: any) => state.userGroupState);
    const adminRole = 1;
    if (userSession.role !== adminRole) {
        return <div>Bạn không có quyền truy cập</div>;
    }
    if (
        // sprintState.loading ||
        // !sprintState.sprints ||
        users.loading ||
        !users.userInfos ||
        userGroupState.loading ||
        projectState.loading
    ) {
        return <LoadingWidget />;
    }
    // if (sprintState.error || users.error) {
    //     return <ErrorWidget error={"Lỗi load data"} />;
    // }
    return (
        <UserGroupView
            // sprints={sprintState.sprints}
            users={users.userInfos}
            userGroups={userGroupState.userGroups}
        />
    );
};
const UserGroupView: FC<{
    // sprints: ISprint[];
    users: IUserInfo[];
    userGroups: IUserGroup[];
}> = ({ users, userGroups }) => {
    const [selectedUserGroup, setSelectedUserGroup] = useState<IUserGroup | null | undefined>();
    // const currentSprint =
    //     sprints.find((sprint: ISprint) => sprint.activeSprint) ?? sprints[0];
    return (
        <div style={{ padding: "0 20px" }} className="new-dashboard">
            {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={2}>
                    <DashboardSidebar
                        backButton={false}
                        currentSprintID={currentSprint._id}
                    />
                </Grid> */}
            <Container>
                <Button
                    onClick={() => {
                        setSelectedUserGroup(null);
                    }}
                >
                    Thêm group
                </Button>
                <Table sx={{ backgroundColor: "#ffff", borderRadius: "12px" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Group Name</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Group member</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userGroups?.map((userGroup: IUserGroup) => {
                            return (
                                <TableRow
                                    style={{ cursor: "pointer" }}
                                    key={userGroup._id}
                                    onClick={() => {
                                        setSelectedUserGroup(userGroup);
                                    }}
                                >
                                    <TableCell align="center">{userGroup.name}</TableCell>
                                    <TableCell align="center">{userGroup.description}</TableCell>
                                    <TableCell align="center">
                                        {userGroup.users
                                            .map((user) => {
                                                return (
                                                    users.find((u) => u._id == user.userId)?.firstName +
                                                    " " +
                                                    users.find((u) => u._id == user.userId)?.lastName
                                                );
                                            })
                                            .filter((u) => !!u)
                                            .join(", ")}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {typeof selectedUserGroup !== "undefined" && (
                    <EditAddUserGroupModal userGroup={selectedUserGroup} onClose={() => setSelectedUserGroup(undefined)} />
                )}
            </Container>
            {/* </Grid> */}
        </div>
    );
};

export default UserGroup;
