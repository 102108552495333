import { IUserInfo } from "../../shared/models/userInfo";

const initState: IUserInfo[] = [];
const UserInfoReducer = (state = initState, action: any) => {
    switch (action.type) {
        case 'GET_ALL_USER':
            return action.data;
        case 'UPDATE_POINT_USER':
            const data = action.data;
            const newsate = state.map((item) => {
                if (item._id == data.userId) {
                    item.point = data.point;
                    return item;
                }
                return item;
            });
            return newsate;
        default:
            return state;
    }
};

export default UserInfoReducer;
