import Container from "@mui/material/Container";
import axios from "axios";
import { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getAllProject } from "../../redux/actions/project.action";
import { getAllStatus } from "../../redux/actions/status.action";
import { getAllTask } from "../../redux/actions/task.action";
import { getTestsFailed, getTestsSuccess } from "../../redux/actions/testCase.action";
import { getCyclesFailed, getCyclesSuccess } from "../../redux/actions/testCycle.action";
import { getAllUserInfo } from "../../redux/actions/userInfo.action";
// import { getTaskAPI } from "../../redux/sagas/task.saga";
import { ExpandLess, MoreHoriz } from "@material-ui/icons";
import { IconButton, useMediaQuery } from "@mui/material";
import { getDataPersonalsGoalAcation } from "../../redux/actions/personalsGoal.action";
import { getSprintReportV2Data } from "../../redux/actions/sprintReport.action";
import { getALLTaskTag } from "../../redux/actions/taskTag.action";
import { getAllUserGroup } from "../../redux/actions/userGroup.action";
import { getAllWorkingTimeInfo } from "../../redux/actions/workingTime.action";
import { RootState } from "../../redux/reducers";
import Config from "../../shared/models/config";
import { IOffDaysWfhDays } from "../../shared/models/offDaysWfhDays";
import { IProject } from "../../shared/models/project";
import { ISprint } from "../../shared/models/sprint";
import { IStatus } from "../../shared/models/status";
import { ITask } from "../../shared/models/task";
import { ICoefficients, IUserInfo, UserCoefficient } from "../../shared/models/userInfo";
import { IWorkingTime } from "../../shared/models/workingTime";
import { roleForPoint, roleViewForPoint } from "../../shared/utils";
import { timezoneSetHours } from "../../util";
import Url from "../../util/url";
import { getUserDisciplineScore, getUserTaskScores } from "../../utils";
import LoadingWidget from "../LoadingWidget";
import DashboardTaskView from "./dashboardTaskView";
import ExpectationAndNotificationComponent from "./expectation-and-notification";
import DashboardSidebarV2 from "./sidebar/sidebarV2";
import "./style.scss";

const DashboardV2 = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllUserInfo());
        dispatch(getAllStatus());
        dispatch(getAllProject());
        dispatch(getALLTaskTag());
        dispatch(getDataPersonalsGoalAcation());
        dispatch(getAllUserGroup());
    }, []);
    const statusState = useSelector((state: RootState) => state.statusState);
    const sprintState = useSelector((state: RootState) => state.sprintState);
    const projectState = useSelector((state: RootState) => state.projectState);
    const userInfoState = useSelector((state: RootState) => state.userInfoState);
    const userInfo = useSelector((state: RootState) => state.authState.user);
    if (
        statusState.loading ||
        !statusState.status ||
        sprintState.loading ||
        !sprintState.sprints ||
        projectState.loading ||
        !projectState.projects ||
        userInfoState.loading ||
        !userInfoState.userInfos ||
        !userInfo
    ) {
        return <LoadingWidget />;
    }
    return (
        <DashboardView
            projects={projectState.projects}
            sprints={sprintState.sprints}
            status={statusState.status}
            users={userInfoState.userInfos}
        />
    );
};

export type DashboardProps = {
    sprints: ISprint[];
    status: IStatus[];
    projects: IProject[];
    users: IUserInfo[];
};
const DashboardView: FC<DashboardProps> = memo(
    (props) => {
        const isMobile = useMediaQuery("(max-width:780px)");
        return (
            <Container
                sx={{
                    maxWidth: "unset !important",
                    padding: isMobile ? "4px !important" : "16px 56px !important",
                }}
                className="dashboard-v2-container"
            >
                <div className="dashboard-grid">
                    <DashboardViewContent
                        {...props}
                        //  sprintId={sprintId}
                    />
                </div>
            </Container>
        );
    },
    (p, n) => {
        if (
            p.projects.length != n.projects.length ||
            p.sprints.length != n.sprints.length ||
            p.status.length != n.status.length ||
            p.users.length != n.users.length
        )
            return false;
        return true;
    }
);

const DashboardViewContent: FC<DashboardProps> = memo(
    (props) => {
        const params: any = useParams();
        const tasks: ITask[] = useSelector((state: RootState) => state.taskState.tasks ?? []);
        const { sprints, users } = props;
        const { id } = params;
        const userInfo: IUserInfo = useSelector((state: RootState) => state.authState.user!);
        const newSprintReports: any = useSelector((state: RootState) => state.sprintReportState.newSprintReports);
        const workingTimes: IWorkingTime[] = useSelector((state: RootState) => state.workingTime.workingTime);
        const offDaysWfhDays: IOffDaysWfhDays[] = useSelector((state: RootState) => state.workingTime.offDaysWfhDays);
        const [userTaskScore, setUserTaskScore] = useState<any>({});
        const [userDisciplineScore, setUserDisciplineScore] = useState<any>({});
        const [coef, setCoef] = useState<{ [userId: string]: ICoefficients }>({});
        let _currentSprint: ISprint | undefined = undefined;
        if (id) {
            _currentSprint = sprints.find(
                (sprint) => sprint._id == id || sprint.shortId == id || (id == "active" && sprint.activeSprint)
            );
        }
        if (!_currentSprint) {
            _currentSprint = sprints.find((sprint) => sprint.activeSprint);
        }
        const currentSprint = _currentSprint ?? sprints[0];
        const history = useHistory();
        const getSearchParams = () => {
            let searchParams = new URLSearchParams(history.location.search);
            let userStr = searchParams.get("user");
            let projectStr = searchParams.get("project");
            return {
                userIds: userStr?.split(",") ?? [],
                projectIds: projectStr?.split(",") ?? [],
            };
        };
        const filterUserWithSearchParams = (ids: string[]): string[] => {
            // bỏ những user_id không có trong users
            return ids
                .map((id) => {
                    return users.find((u) => u._id == id)?._id ?? "";
                })
                .filter((id) => !!id);
        };
        const searchParams = getSearchParams();
        const [currentUserIds, setCurrentUserIds] = useState<string[]>(filterUserWithSearchParams(searchParams.userIds));
        const dispatch = useDispatch();
        const getTestCaseTestCycle = async () => {
            await axios
                .get(Url("test-case/get-test-cases-test-cycles-sprint?sprintID=" + currentSprint._id))
                .then((response) => {
                    let { testCases, testCycles } = response.data;
                    dispatch(getTestsSuccess(testCases));
                    dispatch(getCyclesSuccess(testCycles));
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(getTestsFailed(e));
                    dispatch(getCyclesFailed(e));
                });
        };
        useEffect(() => {
            if (currentSprint) {
                dispatch(getAllTask(currentSprint._id));
                getTestCaseTestCycle();
                let csp = JSON.parse(JSON.stringify(currentSprint));
                if (csp.activeSprint) {
                    // sprint active thi moi can lay data ve, con khong thi data lay bang getSprintReportV2Data
                    let _startSprint = timezoneSetHours(csp.startDate).getTime();
                    let _endSprint = timezoneSetHours(csp.endDate, 23, 59, 59).getTime();
                    // let _today = timezoneSetHours(new Date(), 23, 59, 59).getTime();
                    let start = _startSprint;
                    let end = _endSprint; // query dư ra 1 ngày để lấy trường hợp nghỉ khẩn cấp

                    if (csp) {
                        if (roleViewForPoint(userInfo)) {
                            dispatch(getAllWorkingTimeInfo({ start, end }, userInfo));
                        } else if (userInfo.role === Config.USER_ROLE_ADMIN) {
                            let start = _startSprint;
                            // let end = _endSprint > _today ? _today : _endSprint;
                            dispatch(getAllWorkingTimeInfo({ start, end }));
                        }
                    }
                    let coefs: { [key: string]: ICoefficients } = {};
                    for (let user of users) {
                        if (roleViewForPoint(user)) {
                            coefs[user._id] = new UserCoefficient(user);
                        }
                    }
                    setCoef(coefs);
                } else {
                    dispatch(getSprintReportV2Data(currentSprint)); // neu la sprint cu thi expectation di kem trong nay vi sprintExpData chi co du lieu cua cac sprint truoc do
                }
            }
        }, [currentSprint]);
        useEffect(() => {
            if (tasks?.length && currentSprint?.activeSprint) {
                let data: any = getUserTaskScores({
                    tasks,
                    currentSprint,
                    users: users.filter((u) => {
                        if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                        else return true;
                    }),
                });
                if (JSON.stringify(data).localeCompare(JSON.stringify(userTaskScore)) != 0) {
                    setUserTaskScore(data);
                }
            }
        }, [tasks]);
        useEffect(() => {
            if (
                currentSprint?.activeSprint &&
                offDaysWfhDays &&
                workingTimes &&
                (offDaysWfhDays?.length || workingTimes?.length)
            ) {
                let listDisciplineScore: any = getUserDisciplineScore({
                    currentSprint,
                    users: users.filter((u) => {
                        if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                        else return true;
                    }),
                    workingTimes,
                    offDaysWfhDays,
                });
                if (JSON.stringify(listDisciplineScore).localeCompare(JSON.stringify(userDisciplineScore)) != 0) {
                    setUserDisciplineScore(listDisciplineScore);
                }
            }
        }, [offDaysWfhDays, workingTimes]);
        useEffect(() => {
            if (!currentSprint?.activeSprint) {
                if (Object.keys(newSprintReports)?.length) {
                    let listTaskScore: any = getUserTaskScores({
                        newSprintReports,
                        currentSprint,
                        users: users.filter((u) => {
                            if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                            else return true;
                        }),
                    });
                    let listDisciplineScore: any = getUserDisciplineScore({
                        currentSprint,
                        users: users.filter((u) => {
                            if (roleViewForPoint(userInfo)) return u._id === userInfo._id;
                            else return true;
                        }),
                        newSprintReports,
                    });
                    let coefs: { [key: string]: ICoefficients } = {};
                    for (let key in newSprintReports) {
                        coefs[key] = new UserCoefficient(newSprintReports[key][0]?.coefficients);
                    }
                    setCoef(coefs);
                    setUserTaskScore(listTaskScore);
                    setUserDisciplineScore(listDisciplineScore);
                }
            }
        }, [newSprintReports]);
        const [showRightHeader, setShowRightHeader] = useState(false);
        return (
            <>
                <div className="background-color-2 radius-12 min-width" style={{ padding: 0, height: "fit-content" }}>
                    <DashboardSidebarV2
                        currentSprint={currentSprint}
                        backButton={false}
                        currentUsersInfo={currentUserIds
                            .filter((userId) => {
                                let user = users.find((u) => u._id === userId);
                                if (user && roleForPoint(user)) {
                                    return true;
                                }
                                return false;
                            })
                            .map((userId) => {
                                let user = users.find((u) => u._id === userId);
                                if (user) return user;
                            })}
                        coefs={coef}
                        userTaskScore={userTaskScore}
                        userDisciplineScore={userDisciplineScore}
                    />
                </div>
                <div style={{ padding: 0, overflowX: "hidden" }}>
                    <div className="grid-main-content">
                        <div style={{ position: "relative", marginBottom: 12 }}>
                            {showRightHeader && (
                                <ExpectationAndNotificationComponent
                                    currentSprint={currentSprint}
                                    taskScores={userTaskScore[userInfo._id]}
                                    disciplinePoints={userDisciplineScore[userInfo._id]}
                                />
                            )}
                            <IconButton onClick={() => setShowRightHeader(!showRightHeader)} size="small" style={ showRightHeader ? {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                backgroundColor: "white",
                            } : { backgroundColor: "white" }}>
                                {showRightHeader ? <ExpandLess color="primary" /> : <MoreHoriz color="primary" />}
                            </IconButton>
                        </div>
                        <DashboardTaskView
                            {...props}
                            currentSprint={currentSprint}
                            currentUserIds={currentUserIds}
                            setCurrentUserIds={setCurrentUserIds}
                            userTaskScore={userTaskScore}
                        />
                    </div>
                </div>
            </>
        );
    },
    (p, n) => {
        if (
            p.projects.length != n.projects.length ||
            p.sprints.length != n.sprints.length ||
            p.status.length != n.status.length ||
            p.users.length != n.users.length
        )
            return false;
        return true;
    }
);

export default DashboardV2;
