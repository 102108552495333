import { FC, Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router";
import { Flag } from "@material-ui/icons";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import { Checkbox } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import moment from "moment";
import FormControl from "react-bootstrap/FormControl";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import addIcon from "../../../../assets/images/add.svg";
import { updateCampaigns } from "../../../../redux/actions/campaign.action";
import { pendingProcessingTaskUpdate, updateTask, updateTaskFailed } from "../../../../redux/actions/task.action";
import { RootState } from "../../../../redux/reducers";
import { ICampaign } from "../../../../shared/models/campaign";
import Config from "../../../../shared/models/config";
import { IStatus } from "../../../../shared/models/status";
import { ITask, ITaskHistory, TaskHistory } from "../../../../shared/models/task";
import TaskTag from "../../../../shared/models/taskTag";
import { IUserGroup } from "../../../../shared/models/userGroup";
import { IUserInfo } from "../../../../shared/models/userInfo";
import { checkPermissionChangeTask, isLeaderEditPoint } from "../../../../shared/permissions";
import { calcPoint, compareArray } from "../../../../shared/utils";
import { checkRequirementsChangeStatusField, isAdmin } from "../../../../util";
import MyAvatar from "../../../avatar";
import { checkAllChildTasksAreDone } from "../../Column";
import MyTextBox from "../../widgets/MyTextBox";
import { validatePoint } from "../edit-task-modal/confirmed-points";
import { TASK_FIELDS } from "../edit-task-modal/new_edit_task";
import TaskCantDrag from "../index";
import ZoomImage from "../zoom-image";
import AddSubTask from "./add-sub-task/index";
import "./style.scss";

const SubTasksPreview: FC<{
    parentTask: ITask;
    location: string;
    onSelectedTask: (task: ITask) => void;
    onShowMoveTaskModal?: (value: boolean) => void;
    checkedItems?: { [key: string]: boolean };
    onChange?: (subtaskId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ parentTask, location, onSelectedTask, onShowMoveTaskModal, onChange, checkedItems }) => {
    const dispatch = useDispatch();
    const allTasks: ITask[] = useSelector((state: RootState) => state.taskState.tasks ?? []);
    const campaigns: ICampaign[] = useSelector((state: RootState) => state.campaignState.campaigns ?? []);
    const subTasks = allTasks.filter((t) => t.parentId == parentTask._id);
    const [showAddSubTask, setShowAddSubTask] = useState(false);
    const [progress, setProgress] = useState(0);
    const [expandTask, setExpandTask] = useState(-1);
    const [expandSubTask, setExpandSubTask] = useState(-1);
    const allStatus: IStatus[] = useSelector((state: RootState) => state.statusState.status ?? []);
    const userGroups: IUserGroup[] = useSelector((state: RootState) => state.userGroupState.userGroups);
    const mapStatuses: Map<string, IStatus> = new Map();
    for (let status of allStatus) {
        mapStatuses.set(status._id, status);
    }
    const processing: boolean = useSelector((state: RootState) => state.taskState.processing ?? false);
    const [savingTask, setSavingTask] = useState(false);
    const userInfo: IUserInfo = useSelector((state: RootState) => state.authState.user!);
    useEffect(() => {
        // Add sub task vao campaign task cha
        let listCampaignsUpdate: ICampaign[] = [];
        const parentCampaigns = campaigns.filter((c) => c.listTaskIDs.includes(parentTask._id));
        for (const campaign of parentCampaigns) {
            for (const subTask of subTasks) {
                if (!campaign.listTaskIDs.includes(subTask._id)) {
                    campaign.listTaskIDs.push(subTask._id);
                    listCampaignsUpdate.push(campaign);
                }
            }
        }
        if (listCampaignsUpdate.length) {
            dispatch(updateCampaigns(listCampaignsUpdate));
        }

        let nbSubTasks = 0;
        let nbSubTasksDone = 0;
        subTasks.map((task) => {
            nbSubTasks++;
            if (task.statusId === Config.COLUMN_STATUS.DONE.mongoId) {
                nbSubTasksDone++;
            }
        });
        setProgress(nbSubTasksDone / nbSubTasks);
    }, [subTasks]);
    if (location === "board") {
        return (
            <div className="subtasks">
                {subTasks.map((subTask) => (
                    <Fragment key={subTask._id}>
                        <TaskCantDrag
                            task={subTask}
                            onSelectedTask={() => {
                                // history.push({
                                //     pathname: "/task/" + subTask._id,
                                // });
                                onSelectedTask(subTask);
                            }}
                        />
                    </Fragment>
                ))}
            </div>
        );
    }
    // const [title, setTitle] = useState("task.title");
    // const [editable, setEditable] = useState(false);
    // const [status, setStatus] = useState<any>("task.statusId");
    // const tasks: Task[] = useSelector(
    //     (state: any) => state.taskState.tasks ?? []
    // );
    // useEffect(() => {
    //     setStatus(task.statusId);
    // }, [task.statusId]);
    // const onResetUpdate = (changes: ITaskHistory[], task: ITask) => {
    //     for (let change of changes) {
    //         if (change.field === "title") {
    //             setTitle(task.title);
    //         } else if (change.field === "status") {
    //             setStatus(task.statusId);
    //         } else if (change.field === "point") {
    //             setStatus(task.point);
    //         } else if (change.field === "deadline") {
    //             setStatus(task.deadline);
    //         }
    //     }
    // };

    const getChangedInfo = (newTask: ITask, task: ITask) => {
        let changes: ITaskHistory[] = [];
        const time = new Date();
        const userID = userInfo?._id;
        if (task.title !== newTask.title) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.title,
                    from: task.title,
                    to: newTask.title,
                    userID,
                    time,
                })
            );
        }
        if (task.statusId !== newTask.statusId) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.status,
                    from: task.statusId,
                    to: newTask.statusId,
                    userID,
                    time,
                })
            );
        }
        if (task.point !== newTask.point) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.point,
                    from: task.point,
                    to: newTask.point,
                    userID,
                    time,
                })
            );
        }
        if (task.leadDevPoints !== newTask.leadDevPoints) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.expectPoints,
                    from: task.leadDevPoints,
                    to: newTask.leadDevPoints,
                    userID,
                    time,
                })
            );
        }
        if (task.deadline?.getTime() !== newTask.deadline?.getTime()) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.deadline,
                    from: task.deadline,
                    to: newTask.deadline,
                    userID,
                    time,
                })
            );
        }

        if (compareArray(task.tags, newTask.tags) != 0) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.tags,
                    from: task.tags,
                    to: newTask.tags,
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        if(task.tagValue !== newTask.tagValue) {
            changes.push(
                new TaskHistory({
                    field: TASK_FIELDS.tagValue,
                    from: task.tagValue,
                    to: newTask.tagValue,
                    userID,
                    time,
                    sendedMessage: true,
                })
            );
        }
        return changes;
    };
    const onUpdateTask = (field: any, task: ITask) => {
        let { title, status, point, deadline, leadDevPoints, tagValue, tags } = field;
        if (!!!title) title = task.title;
        if (!!!status) status = task.statusId;
        if (!!!point) point = task.point;
        if (!!!deadline) deadline = task.deadline;
        if (!!!leadDevPoints) leadDevPoints = task.leadDevPoints;
        if(!!!tagValue) tagValue = task.tagValue;
        if(!!!tags) tags = task.tags;
        if (!savingTask) {
            setSavingTask(true);
            dispatch(pendingProcessingTaskUpdate());
            setTimeout(() => {
                const taskUpdate = {
                    ...task,
                    statusId: status,
                    status: mapStatuses.get(status),
                    title: title,
                    point: point,
                    deadline: deadline,
                    leadDevPoints: leadDevPoints,
                    tagValue: tagValue, 
                    tags: tags,
                };
                const changes = getChangedInfo(taskUpdate, task);
                
                const histories = (changes ?? []).concat(task.histories ?? []);
                const newTask: ITask = {
                    ...taskUpdate,
                    histories,
                };
                if (
                    checkPermissionChangeTask({
                        user: userInfo,
                        task: task,
                        newTask,
                        onFeedBack: (msg) => {
                            // onResetUpdate(changes, task);
                            toast(() => <div>{msg}</div>, {
                                position: "bottom-center",
                                type: "error",
                            });
                            setSavingTask(false);
                            dispatch(updateTaskFailed(""));
                        },
                        userGroups
                    })
                ) {
                    let updateStatus = !!changes.find((c) => c.field === "status");
                    dispatch(updateTask({ ...newTask, updateStatus: updateStatus }));
                    setSavingTask(false);
                    setTimeout(() => setSavingTask(false), 1000);
                }
            }, 500);
        }
    };
    return (
        <div className="subtasks">
            {location === 'subtask' ? null : <div className="subtasks-title">
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "10px",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <h5 style={{ paddingRight: "10px" }}>Subtasks</h5>
                    {subTasks[0] && (
                        <div
                            style={{
                                width: " 30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#e7e3e3",
                                marginRight: "15px",
                            }}
                        >
                            <TrendingFlatOutlinedIcon
                                onClick={() => onShowMoveTaskModal && onShowMoveTaskModal(true)}
                                titleAccess="Move Subtasks"
                                style={{ cursor: "pointer", marginTop: "2px", marginLeft: "2px" }}
                            />
                        </div>
                    )}
                </div>
                <div>
                    <img
                        className="add-icon"
                        onClick={() => {
                            if (parentTask.statusId === Config.COLUMN_STATUS.DONE.mongoId) {
                                toast(() => <div>Không tạo thêm sub task cho task đã hoàn thành!</div>, {
                                    position: "bottom-center",
                                    type: "warning",
                                });
                            } else setShowAddSubTask(true);
                        }}
                        src={addIcon}
                        title="Add sub tasks"
                    />
                </div>
            </div>}
            {progress > 0 && (
                <div className="progressive-subtask">
                    <div className="now" style={{ width: `${progress * 100}%` }}></div>
                </div>
            )}
            {showAddSubTask && <AddSubTask parentTask={parentTask} onHide={() => setShowAddSubTask(false)} />}
            <div className="wrap-task" style={{ margin: 0 }}>
                {subTasks
                    .sort((a, b) => a.shortId.localeCompare(b.shortId))
                    .map((task, index) => {
                        return (
                            <div style={{ borderBottom: "1px solid #eee" }} key={task._id}>
                                <MainTaskPreview
                                    onChange={onChange && onChange}
                                    checkedItems={checkedItems}
                                    task={task}
                                    index={index}
                                    expandTask={expandTask}
                                    setExpandTask={setExpandTask}
                                    allowEdit={true}
                                    onUpdateTask={(field: any) => {
                                        onUpdateTask(field, task);
                                    }}
                                    processing={processing}
                                    listSubtask={subTasks}
                                />
                                <Collapse in={index == expandTask} timeout="auto" unmountOnExit>
                                    <MainTaskContent
                                        task={task}
                                        onUpdateTask={(field: any) => {
                                            onUpdateTask(field, task);
                                        }}
                                        processing={processing}
                                    />
                                    <div style={{ paddingLeft: 60 }}>
                                        <SubTasksPreview
                                            location="subtask"
                                            parentTask={task}
                                            onSelectedTask={(task) => {}}
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default SubTasksPreview;

const MainTaskPreview = ({
    task,
    index,
    expandTask,
    setExpandTask = (v) => {},
    allowEdit,
    isChildOfChild = false,
    onUpdateTask,
    processing,
    onChange,
    checkedItems,
    listSubtask
}: {
    task: ITask;
    index: number;
    expandTask?: number;
    setExpandTask?: (v: number) => void;
    allowEdit: boolean;
    isChildOfChild?: boolean;
    onUpdateTask: any;
    processing: boolean;
    checkedItems?: { [key: string]: boolean };
    onChange?: (subtaskId: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    listSubtask? : ITask[];
}) => {
    const allStatus: IStatus[] = useSelector((state: RootState) => state.statusState.status ?? []);
    const mapStatuses: Map<string, IStatus> = new Map();
    for (let status of allStatus) {
        mapStatuses.set(status._id, status);
    }
    const [title, setTitle] = useState(task.title);
    const [editable, setEditable] = useState(false);
    const [status, setStatus] = useState<any>(task.statusId);
    const [savingTask, setSavingTask] = useState(false);
    // const userInfo: IUserInfo = useSelector(
    //     (state: any) => state.authState.user
    // );
    const tasks: ITask[] = useSelector((state: RootState) => state.taskState.tasks ?? []);
    useEffect(() => {
        setStatus(task.statusId);
    }, [task.statusId]);
    const taskTags: TaskTag[] = useSelector((state: RootState) => state.taskTagState.taskTags ?? []);
    const tag = taskTags.find((t) => task.tags.includes(t._id));
    const point = calcPoint(task, tasks, taskTags);
    return (
        <div>
            <div className="task" key={task._id}>
                <div
                    style={{
                        marginLeft: !isChildOfChild ? 0 : "52px",
                        marginRight: "16px",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    {listSubtask?.find((t) => t._id == task?._id) && (
                        <Checkbox
                            size="small"
                            value={!!checkedItems && !!checkedItems[task?._id]}
                            onChange={onChange && onChange(task?._id)}
                            style={{marginRight:"10px"}}
                        />
                    )}
                    {isChildOfChild ? <SubdirectoryArrowRightIcon /> : <></>}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <a className="subtask-shortId" href={"/task/" + task._id} target="_blank" style={{ fontSize: '0.8em' }}>
                            {task.shortId}
                        </a>
                        {savingTask ? <span>saving...</span> : <></>}
                    </div>
                    { (!!point && point > 0) && (
                        <>
                            <div style={{ marginTop: "3px", cursor: "pointer" }} data-tip data-for={"preview-subtask-task-point-" + task._id}>
                                <div className="point">{point}</div>
                            </div>
                            <ReactTooltip id={"preview-subtask-task-point-" + task._id} type="info">
                                <div>
                                    <div>Số point của task: {task.point}</div>
                                    <div>Tổng số point của task: {point}</div>
                                </div>
                            </ReactTooltip>
                        </>
                    ) }
                    {editable && allowEdit ? (
                        <input
                            type="text"
                            value={title}
                            autoFocus={editable}
                            disabled={
                                task.status?._id === Config.COLUMN_STATUS.DONE.mongoId ||
                                task.status?._id === Config.COLUMN_STATUS.PROD_VALIDATION.mongoId ||
                                processing
                            }
                            onBlur={(e) => {
                                setEditable(false);
                                if (title != task.title) {
                                    onUpdateTask({ title: title }, task);
                                }
                            }}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            style={{ maxWidth: "300px", width: "100%" }}
                        />
                    ) : (
                        <div
                            className="subtask-title"
                            onClick={() => {
                                setEditable(true);
                                setTitle(task.title);
                            }}
                        >
                            {task.title}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        alignItems: "center",
                    }}
                >
                    {tag && (
                        <div style={{ color: tag.color, padding: 4 }}>
                            <Flag color="inherit" fontSize="small" />
                        </div>
                    )}
                    <div className="subtask-assignee-avatar">
                        <MyAvatar url={task.assignee?.avatar} />
                    </div>
                    <div>
                        {task.deadline && (
                            <div
                                style={{
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                }}
                            >
                                <AvTimerIcon
                                    style={{
                                        width: "17px",
                                        height: "17px",
                                    }}
                                />{" "}
                                {task.deadline?.toLocaleDateString()}
                            </div>
                        )}
                        <div
                            className="subtask-status"
                            style={{
                                backgroundColor: task.status?.color,
                            }}
                        >
                            {allowEdit ? (
                                <select
                                    disabled={processing}
                                    className="select-status"
                                    onChange={(e) => {
                                        if (e.target.value !== task.status?._id) {
                                            if (!checkRequirementsChangeStatusField(task, e.target.value)) return;
                                            let allowEdit = true;
                                            if (e.target.value === Config.COLUMN_STATUS.DONE.mongoId) {
                                                allowEdit = checkAllChildTasksAreDone(task, tasks);
                                            }
                                            if (allowEdit) {
                                                onUpdateTask(
                                                    {
                                                        status: e.target.value,
                                                    },
                                                    task
                                                );
                                                setStatus(e.target.value);
                                            }
                                        }
                                    }}
                                    value={status}
                                >
                                    {allStatus.map((s: IStatus) => (
                                        <option key={s._id} value={s._id}>
                                            {s.title}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                task.status?.title
                            )}
                        </div>
                    </div>

                    <div
                        className={"expand-task " + (expandTask == index ? "expanded" : "")}
                        onClick={() => {
                            if (expandTask != index) setExpandTask(index);
                            else setExpandTask(-1);
                        }}
                    >
                        <ExpandMore />
                    </div>
                </div>
            </div>
        </div>
    );
};

const MainTaskContent = memo(({ task, onUpdateTask, processing }: { task: ITask; onUpdateTask: any; processing: boolean }) => {
    const [zoomImageUrl, setZoomImageUrl] = useState("");
    const [points, setPoints] = useState(task.point);
    // const [expectPoints, setExpectPoints] = useState(task.leadDevPoints);
    const [deadline, setDeadline] = useState(task.deadline);
    const [deadlineTmp, setDeadlineTmp] = useState(task.deadline);
    const handlePointsChange = (value: any) => {
        setPoints(value);
        onUpdateTask({ point: value });
    };
    // const users: IUserInfo[] = useSelector((state: any) => state.userInfoState.userInfos ?? []);
    const userGroups: IUserGroup[] = useSelector((state: RootState) => state.userGroupState.userGroups);
    const userInfo: IUserInfo = useSelector((state: RootState) => state.authState.user!);
    const isLeaderGroup = (userId: string) => {
        let res = false;
        userGroups.forEach((group) => {
            group.users.forEach((user) => {
                if (user.userId === userId && user.role === "lead") {
                    if (task.projectId === group.projectId)
                        //la lead cua group thuoc project task
                        res = true;
                }
            });
        });
        return res;
    };

    let taskSprint = task.sprint;
    const taskTags: TaskTag[] = useSelector((state: any) => state.taskTagState.taskTags ?? []);
    const tag = taskTags.find((t) => task.tags.includes(t._id));
    return (
        <>
            {zoomImageUrl && <ZoomImage url={zoomImageUrl} closeZoomImage={() => setZoomImageUrl("")} />}
            <div className="main-task-content">
                { !!tag && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10 }}>
                        <div style={{ width: '200px' }}><strong>{tag.description}</strong></div>
                        <MyTextBox
                            text={task.tagValue + ""}
                            title="tagValue"
                            onChange={(value: any) => {
                                let _val: any;
                                try {
                                    _val = parseInt(value);
                                } catch (e) {}
                                if (!Number.isNaN(_val)) {
                                    onUpdateTask({ tagValue: _val });
                                    const point = Math.ceil((_val * tag.point) / tag.value);
                                    handlePointsChange(point);
                                }
                            }}
                            type="number"
                        />
                    </div>
                ) }
                <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10 }}>
                    <div style={{ width: '200px' }}><strong>Estimated Points</strong></div>
                    <MyTextBox
                        text={points + ""}
                        title="point"
                        disabled={
                            processing ||
                            (points === undefined || points === null
                                ? false
                                : !(
                                    isLeaderGroup(userInfo._id) || //leader group
                                    task?.reporterId === userInfo._id || isAdmin(userInfo) || isLeaderEditPoint(userInfo)
                                ))
                        }
                        onChange={(value: any) => {
                            let _val: any;
                            try {
                                _val = parseInt(value);
                            } catch (e) {}
                            if (!Number.isNaN(_val)) {
                                if (!validatePoint(_val)) {
                                    return;
                                }
                                handlePointsChange(value);
                            }
                        }}
                        type="number"
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <div style={{ width: '200px' }}><strong>Deadline</strong></div>
                        <FormControl
                            type="datetime-local"
                            name="deadline"
                            value={moment(deadlineTmp).format("YYYY-MM-DDTHH:mm")}
                            min={moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DDTHH:mm")}
                            max={moment(taskSprint?.endDate.setHours(23, 59, 59, 0)).format("YYYY-MM-DDTHH:mm")}
                            onChange={(e) => {
                                setDeadlineTmp(new Date(e.target.value));
                            }}
                            onBlur={(e) => {
                                setDeadline(deadlineTmp);
                                onUpdateTask({ deadline: deadlineTmp });
                            }}
                            disabled={
                                ((deadline != null || deadline != undefined
                                    ? !(
                                        isLeaderGroup(userInfo._id) || //leader group
                                        task?.reporterId === //reporter
                                            userInfo._id ||
                                        userInfo.role === //leader all
                                            Config.USER_ROLE_LEADER ||
                                        userInfo.role === //leader all
                                            Config.USER_ROLE_ADMIN
                                    )
                                    : false) &&
                                    task.type.toLowerCase() !== "sub task") ||
                                processing
                            }
                        />
                </div>
            </div>
        </>
    );
});
