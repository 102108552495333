import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FC } from "react";
import Url from "../../../../../util/url";

const Editor: FC<{
    onReady?: any;
    onBlur?: any;
    onFocus?: any;
    onChange: any;
    data: string;
}> = ({ onReady = null, onBlur = null, onFocus = null, onChange, data }) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={data}
            config={{
                ckfinder: {
                    uploadUrl: Url("upload/editor"),
                },
                // link: {
                //     addTargetToExternalLinks: true,
                // },
            }}
            onReady={onReady}
            onFocus={onFocus}
            onChange={(e, editor) => onChange(e, editor)}
            onBlur={onBlur}
        />
    );
};

export default Editor;
