import { Add, Clear, Delete, Edit, Flag, MoreHoriz } from "@material-ui/icons";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { updateTaskTag } from "../../../../../redux/actions/taskTag.action";
import { getAllUserGroup } from "../../../../../redux/actions/userGroup.action";
import TaskTag, { TaskTagStatus } from "../../../../../shared/models/taskTag";
import { UserGroup } from "../../../../../shared/models/userGroup";
import { IUserInfo } from "../../../../../shared/models/userInfo";
import { isAdmin, isLeadDev, isLeader } from "../../../../../shared/permissions";
import LoadingWidget from "../../../../LoadingWidget";

const SelectTags:FC<({
    tags: string[],
    onChange: (tags: string[]) => void,
    disabled: boolean,
    currentProjectId?: string
})> = ({
    disabled,
    onChange,
    tags,
    currentProjectId
}) => {
    const taskTagState = useSelector((state: any) => state.taskTagState);
    const [showTaskTagModal, setShowTaskTagModal] = useState(false);
    const userGroups: UserGroup[] = useSelector((state: any) => state.userGroupState.userGroups);
    if(taskTagState.loading) {
        return <LoadingWidget />
    }
    const listGroupId = userGroups.filter((userGroup: UserGroup) => userGroup.projectId === currentProjectId).map((userGroup: UserGroup) => userGroup._id);
    const taskTags: TaskTag[] = (taskTagState.taskTags ?? []).filter((taskTag: TaskTag) => {
        return (taskTag.status === TaskTagStatus.ACTIVE) 
            && (!listGroupId.length || listGroupId.includes(taskTag.userGroupId));
    });
    const onClearTags = () => {
        if(window.confirm("Bạn muốn xoá tag? Lưu ý tất cả các tag con cũng bị xoá!")) {
            onChange([]);
        }
    }
    const selectedTag = taskTags.find((u) => tags.includes(u._id)) ?? null;
    console.log("selectedTag", selectedTag, tags);
    return (
        <div style={{ display: "flex", alignItems: "center", gap: 10, width: "100%" }}>
            <Select
                className="select-tag"
                formatOptionLabel={getLabel}
                options={taskTags}
                isDisabled={disabled}
                value={selectedTag}
                onChange={(tag) => {
                    if(tag?._id) {
                        onChange([tag._id]);
                    }
                }}
            />
            <Button disabled={disabled} variant="error" size="sm" onClick={onClearTags}><Clear /></Button>
            <Button disabled={disabled} variant="primary" size="sm" onClick={() => setShowTaskTagModal(true)}><MoreHoriz /></Button>
            {showTaskTagModal && <TaskTagManagement taskTags={taskTags} onHide={() => setShowTaskTagModal(false)} />}
        </div>
    );
};

const getLabel = ({ color, name }: TaskTag) => {
    return <div style={{ color: color }}><Flag color="inherit" fontSize="small" /> <span style={{ color: 'black' }}>{name}</span></div>
}

const TaskTagManagement: FC<{
    taskTags: TaskTag[];
    onHide: () => void;
}> = ({ taskTags, onHide }) => {
    const [taskTag, setTaskTag] = useState<TaskTag | null>();
    const dispatch = useDispatch();
    const onDelete = (taskTag: TaskTag) => {
        if(window.confirm("Are you sure you want to delete this tag?")) {
            taskTag.status = TaskTagStatus.INACTIVE;
            dispatch(updateTaskTag(taskTag));
        }
    }
    const userInfo: IUserInfo = useSelector((state: any) => state.authState.user);
    const isViewer = !(isAdmin(userInfo) || isLeadDev(userInfo) || isLeader(userInfo));
    const style:CSSProperties = { 
        display: "flex", 
        alignItems: "center", 
        gap: 4, 
        padding: 8,
        borderRadius: 8,
        border: "1px solid #eeeeee",
        cursor: "pointer",
        textAlign: "center"
    }
    return (
        <div>
            <Modal show={true} onHide={() => onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title>Task Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ gap: 12, display: "flex", flexDirection: "column" }}>
                    <div style={{ ...style, border: "none", padding: 0 }}>
                        <div style={{ width: 30, height: 30, borderRadius: 4, marginRight: 8 }}></div>
                        <div style={{ flex: 1, textAlign: "left" }}>Tên</div>
                        <div style={{ width: 70 }}>Số lượng</div>
                        <div style={{ width: 70 }}>Point</div>
                        { !isViewer && (
                            <>
                                <div style={{ width: 40 }}></div>
                                <div style={{ width: 40 }}></div>
                            </>
                        ) }
                    </div>
                    { taskTags.map((taskTag) => {
                        return (
                            <div key={taskTag._id} style={style}>
                                <div style={{ width: 30, height: 30, backgroundColor: taskTag.color, borderRadius: 4, marginRight: 8 }}></div>
                                <div style={{ flex: 1, textAlign: "left" }}>
                                    <div>{taskTag.name}</div>
                                    <div style={{ fontSize: '0.8em' }}>({taskTag.description})</div>
                                </div>
                                <div style={{ width: 70 }}>{taskTag.value}</div>
                                <div style={{ width: 70 }}>{taskTag.point}</div>
                                { !isViewer && (
                                    <>
                                        <Button variant="primary" size="sm" onClick={() => setTaskTag(taskTag)}><Edit /></Button>
                                        <Button variant="danger" size="sm" onClick={() => onDelete(taskTag)}><Delete /></Button>
                                    </>
                                ) }
                            </div>
                        );
                    }) }
                </Modal.Body>
                <Modal.Footer>
                    {!isViewer && (<Button variant="primary" size="sm" onClick={() => {
                        setTaskTag(new TaskTag({}));
                    }}><Add /></Button>)}
                    <Button variant="secondary" onClick={() => onHide()}>Close</Button>
                </Modal.Footer>
            </Modal>
            { taskTag && (
                <EditTaskTagModal
                    onHide={() => setTaskTag(null)}
                    taskTag={taskTag}
                />
            ) }
        </div>
    );   
}

const EditTaskTagModal: FC<{
    taskTag: TaskTag;
    onHide: () => void;
}> = ({ onHide, taskTag }) => {
    const isCreateNew = !taskTag._id;
    const [color, setColor] = useState(taskTag.color ? taskTag.color : "#eeeeee");
    const [userGroupId, setUserGroupId] = useState(taskTag.userGroupId);
    const nameRef = useRef<HTMLInputElement | null>(null);
    const descriptionRef = useRef<HTMLInputElement | null>(null);
    const pointRef = useRef<HTMLInputElement | null>(null);
    const valueRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        setColor(taskTag.color ? taskTag.color : "#eeeeee");
        setUserGroupId(taskTag.userGroupId);
        nameRef.current && (nameRef.current.value = taskTag.name);
        descriptionRef.current && (descriptionRef.current.value = taskTag.description);
        pointRef.current && (pointRef.current.value = taskTag.point.toString());
        valueRef.current && (valueRef.current.value = taskTag.value.toString());
    }, [taskTag]);
    const dispatch = useDispatch();
    const onUpdate = () => {
        const name = nameRef.current?.value;
        const description = descriptionRef.current?.value;
        const point = parseInt(pointRef.current?.value ?? "0");
        const value = parseInt(valueRef.current?.value ?? "0");
        if(!name) {
            toast("Name is required", { type: "error" });
            return;
        }
        if(isNaN(point) || point <= 0) {
            toast("Point is required", { type: "error" });
            return;
        }
        if(isNaN(value) || value < 0) {
            toast("Value is required", { type: "error" });
            return;
        }
        if(!userGroupId) {
            toast("User group is required", { type: "error" });
            return;
        }
        taskTag.name = name;
        taskTag.description = description ?? "";
        taskTag.point = point;
        taskTag.value = value;
        taskTag.color = color ?? '#eeeeee';
        taskTag.userGroupId = userGroupId;
        const time = Date.now();
        if(isCreateNew) {
            taskTag.createdAt = time;
        }
        taskTag.updatedAt = time;
        console.log(taskTag);
        dispatch(updateTaskTag(taskTag));
        onHide();
    }

    return (
        <Modal show={true} onHide={() => onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>{isCreateNew ? "Add Tag" : "Edit Tag " + taskTag.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ gap: 12, display: "flex", flexDirection: "column" }}>
                <InputGroup>
                    <InputGroup.Text style={{ width: 150 }}>Tên thẻ</InputGroup.Text>
                    <FormControl ref={nameRef} placeholder="Tên thẻ" />
                </InputGroup>
                <InputGroup >
                    <InputGroup.Text style={{ width: 150 }}>Mô tả (Số lượng)</InputGroup.Text>
                    <FormControl ref={descriptionRef} placeholder="Mô tả" />
                </InputGroup>
                <SelectColor
                    value={color}
                    onChange={setColor}
                />
                <InputGroup>
                    <InputGroup.Text style={{ width: 150 }}>Thời gian (Point)</InputGroup.Text>
                    <FormControl ref={pointRef} placeholder="Thời gian (Point)" type="number" />
                </InputGroup>
                <InputGroup>
                    <InputGroup.Text style={{ width: 150 }}>Số lượng</InputGroup.Text>
                    <FormControl ref={valueRef} placeholder="Số lượng" type="number" />
                </InputGroup>
                <SelectUserGroup
                    value={userGroupId}
                    onChange={setUserGroupId}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onUpdate}>Update</Button>
                <Button variant="secondary" onClick={() => onHide()}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

const SelectColor: FC<{
    value: string;
    onChange: (color: string) => void;
}> = ({ value, onChange }) => {
    return (
        <InputGroup>
            <InputGroup.Text style={{ width: 150 }}>Chọn màu</InputGroup.Text>
            <div style={{ width: 50 }}>
                <FormControl placeholder="Color" type="color" value={value} onChange={(e) => onChange(e.target.value)} />
            </div>
        </InputGroup>
    )
}

const SelectUserGroup: FC<{
    value: string;
    onChange: (userGroupId: string) => void;
}> = ({ value, onChange }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllUserGroup());
    }, []);
    const userGroupState = useSelector((state: any) => state.userGroupState);
    if(userGroupState.loading) {
        return <LoadingWidget />
    }
    const userGroups: UserGroup[] = userGroupState.userGroups ?? [];
    return (
        <InputGroup>
            <InputGroup.Text style={{ width: 150 }}>Chọn nhóm</InputGroup.Text>
            <div style={{ width: 'calc(100% - 150px)' }}>
                <Select
                    options={userGroups}
                    getOptionLabel={(option) => option.name}
                    value={userGroups.find((u) => u._id === value)}
                    onChange={(userGroup) => onChange(userGroup?._id ?? "")}
                />
            </div>
        </InputGroup>
    );
}

export default SelectTags;
