import { ITask } from "../../shared/models/task";
import { NewTaskAction, NewTaskTypes } from "../actions/newTask.action";

export interface NewTaskState {
    loading: boolean;
    tasks: ITask[];
    error?: any;
    updating?: boolean
}

const initState: NewTaskState = {
    loading: false,
    tasks: [],
};

const newTaskState = (state = initState, action: NewTaskAction): NewTaskState => {
    switch (action.type) {
        case NewTaskTypes.getTaskBySprintId:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };
        case NewTaskTypes.getTaskBySprintIdSuccess:
            return {
                ...state,
                ...{
                    loading: false,
                    tasks: action.tasks ?? [],
                },
            };
        case NewTaskTypes.getTaskBySprintIdFailed:
            return {
                ...state,
                ...{
                    loading: false,
                    error: action.error,
                },
            };
        case NewTaskTypes.updateTasks:
            return {
                ...state,
                ...{
                    updating: true,
                },
            };
        case NewTaskTypes.updateTasksSuccess:
            if(action.tasks) {
                for(const task of action.tasks) {
                    const index = state.tasks.findIndex((t) => t._id === task._id);
                    if(index !== -1) {
                        state.tasks[index] = task;
                    } else {
                        state.tasks.push(task);
                    }
                }
            } 
            return {
                ...state,
                ...{
                    updating: false,
                },
            }
        case NewTaskTypes.updateTasksFailed:
            return {
                ...state,
                ...{
                    updating: false,
                    error: action.error,
                },
            }
        default:
            return state;
    }
};

export default newTaskState;
