import TaskTag from "../../shared/models/taskTag";

export enum TaskTagTypes {
    getALLTaskTag = "getALLTaskTag",
    getALLTaskTagSuccess = "getALLTaskTagSuccess",
    getALLTaskTagFailed = "getALLTaskTagFailed",
    updateTaskTag = "updateTaskTag",
    updateTaskTagFailed = "updateTaskTagFailed",
}

export interface ITaskTagAction {
    type: TaskTagTypes
    taskTags?: TaskTag[]
    error?: any
}

export const getALLTaskTag = (): ITaskTagAction => {
    return {
        type: TaskTagTypes.getALLTaskTag,
    }
}

export const getALLTaskTagSuccess = (taskTags: TaskTag[]): ITaskTagAction => {
    return {
        type: TaskTagTypes.getALLTaskTagSuccess,
        taskTags,
    }
}

export const getALLTaskTagFailed = (error: any): ITaskTagAction => {
    return {
        type: TaskTagTypes.getALLTaskTagFailed,
        error,
    }
}

export const updateTaskTag = (taskTag: TaskTag): ITaskTagAction => {
    return {
        type: TaskTagTypes.updateTaskTag,
        taskTags: [taskTag],
    }
}

export const updateTaskTagFailed = (error: any): ITaskTagAction => {
    return {
        type: TaskTagTypes.updateTaskTagFailed,
        error,
    }
}
